import chroma from 'chroma-js'
import { apply, identity, pipe, getPath } from '@anewgo/functions'
import { apolloClient } from '../graphql/enhancers'
import {
  siteplans,
  getAssetsPrefix,
  getImagePrefix,
  cloudinaryPrefix,
} from '@anewgo/utils'
import {
  addParam,
  addPath,
  getUrlFromQuery,
  makeQuery,
} from '../utils/restQueryBuilder'
import { MAX_PLANCARD_MEDIA_HT } from '../constants'
import {
  UPSERT_PROSPECT,
  DELETE_PROSPECT_FAVORITE,
  GET_PROSPECT_BASE_INFO,
  SEND_REGISTRATION_EMAIL,
  SEND_WELCOME_EMAIL,
} from '../graphql'
import * as reducers from '../store/reducers'
import LotStatus from '../constants/lotStatus'
import { BuilderStatus } from '../constants/BuilderStatus'

const __log = process.env.NODE_ENV === 'development'
const { isInventoryLot } = siteplans

function getMyHomeLink(clientName) {
  return `${process.env.REACT_APP_TRUSS}/client/${clientName}/`
}
function getSelectionLink(prospect, clientName, favorite) {
  return `${process.env.REACT_APP_TRUSS}/client/${clientName}/community/${
    favorite.community.name
  }/plan/${favorite.plan.name}/myHome?elevId=${
    favorite.elevationId
  }&prospectId=${userIdentifier(prospect)}&favoriteId=${favorite.id}`
}
function getFavoritesLink(prospect, clientName) {
  return `${
    process.env.REACT_APP_TRUSS
  }/client/${clientName}/compareFavorites?email=${
    prospect.email
  }&name=${encodeURI(prospect.name)}`
}

export function addProspect({
  clientName,
  email,
  name,
  picture,
  contactMethods,
  communityId,
  planName,
  uiConfig,
  phone,
  isAutoLogin = false,
  isRegistration = false,
}) {
  return apolloClient
    .mutate({
      mutation: UPSERT_PROSPECT,
      // This uses the default errorPolicy of 'none', which causes mutation to NOT update the cache
      // AND to throw an error, when there are any GraphQL errors in the result.
      variables: {
        clientName,
        input: {
          email,
          name,
          picture,
          contactMethods,
          communityId,
          planName,
          phone,
          consultantEmail: uiConfig.localAgent
            ? uiConfig.localAgent.email
            : null,
          consultantName: uiConfig.localAgent ? uiConfig.localAgent.name : null,
          source: 'truss',
          isAutoLogin,
          isRegistration,
        },
      },
    })
    .then((result) => result?.data?.upsertProspect)
}

export async function sendRegistrationEmail(prospect, clientName) {
  return apolloClient
    .mutate({
      mutation: SEND_REGISTRATION_EMAIL,
      variables: {
        clientName,
        prospectId: prospect.id,
        input: {
          email: prospect.email,
          contactMethods: prospect.preferredContactMethods,
          source: 'truss',
          communityId: prospect.communityId,
          floorplanOfInterest: prospect.floorplanOfInterest,
        },
      },
    })
    .then((response) => {
      return getPath('data', 'sendRegistrationEmail')(response)
    })
    .catch((err) => {
      throw new Error(`Error sending prospect registration email.${err}`)
    })
}

export async function sendWelcomeEmail(
  prospect,
  clientName,
  newProspectFavorites,
  communityId,
  agentEmail
) {
  return apolloClient
    .mutate({
      mutation: SEND_WELCOME_EMAIL,
      variables: {
        clientName,
        prospectId: prospect.id,
        communityId,
        agentEmail,
        myHomeAppLink: getMyHomeLink(clientName),
      },
    })
    .then((response) => {
      return getPath('data', 'sendWelcomeEmail')(response)
    })
    .catch((err) => {
      throw new Error(`Error sending prospect welcome email.${err}`)
    })
}

////////////////////////////////////////////////////////////////////////////////
// exported functions
////////////////////////////////////////////////////////////////////////////////

/**
 * This function implements a timer that will make a call to a callback function
 * when a given timeout period has expired. Implementing a timeout period can be
 * as simple as using setInterval or setTimeout.
 * But if a device goes to sleep mode, OS/browser implementations vary and
 * are not standardized on what setInterval/setTimeout would do when the device
 * is awaken. Will it use the wall clock to increment the tick, or will it
 * increment the tick based on the last known tick value before the device went
 * into sleep mode? This function is implemented to address the ambiguity. Caller
 * of this function is responsible for canceling this timer if needed.
 * @param {*} cb - callback function when the timer expires
 * @param {*} timeout - the timeout period in ms
 * @returns an intervalID for the timer in case the timer needs
 * to be cleared by user of this function
 */
export function absoluteSetInterval(cb, timeout) {
  let baseTime = Date.now()
  const callHandler = () => {
    if (Date.now() - baseTime >= timeout) {
      baseTime = Date.now()
      cb()
    }
  }
  return setInterval(callHandler, 1000)
}

export function handleFavoriteDelete(
  track,
  favoriteToDelete,
  deleteEvent,
  dispatch,
  prospect,
  client,
  setFavoriteToDelete
) {
  const trackingProps = {
    communityName: favoriteToDelete.community
      ? favoriteToDelete.community.name
      : null,
    communityId: favoriteToDelete.community
      ? favoriteToDelete.community.id
      : null,
    planName: favoriteToDelete.plan ? favoriteToDelete.plan.name : null,
    planId: favoriteToDelete.plan ? favoriteToDelete.plan.id : null,
    elevationId: favoriteToDelete.elevation
      ? favoriteToDelete.elevation.id
      : null,
    elevationCaption: favoriteToDelete.elevation
      ? favoriteToDelete.elevation.caption
      : null,
    lotId: favoriteToDelete.lot ? favoriteToDelete.lot.id : null,
    lotName: favoriteToDelete.lot ? favoriteToDelete.lot.name : null,
    lotAddress: favoriteToDelete.lot ? favoriteToDelete.lot.address : null,
    inventoryId: favoriteToDelete?.lot?.inventory
      ? favoriteToDelete.lot?.inventory?.id
      : null,
  }
  track(deleteEvent, {
    ...trackingProps,
    referrer: 'compare_favorites',
  })
  if (prospect)
    deleteFavoriteFromDB(favoriteToDelete.id, client.altName, prospect.id)
  dispatch(reducers.deleteFavorite(favoriteToDelete))
  setFavoriteToDelete(null)
}

export function deleteFavoriteFromDB(id, clientName, prospectId) {
  return apolloClient
    .mutate({
      mutation: DELETE_PROSPECT_FAVORITE,
      variables: {
        clientName,
        id,
      },
      update: (cache) => {
        cache.modify({
          id: `Prospect:${prospectId}`,
          fields: {
            favorites(existingFavorites, { readField }) {
              return existingFavorites.filter(
                (favorite) => readField('id', favorite) !== id
              )
            },
          },
        })
      },
    })
    .then((data) => {
      return getPath('data', 'deleteFavoriteForProspect')(data)
    })
}

export async function getProspectBaseInfo(email) {
  return apolloClient.query({
    query: GET_PROSPECT_BASE_INFO,
    variables: {
      email: email,
    },
    fetchPolicy: 'network-only',
  })
}

export function getScrollBarWidth() {
  var inner = document.createElement('p')
  inner.style.width = '100%'
  inner.style.height = '200px'

  var outer = document.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '0px'
  outer.style.left = '0px'
  outer.style.visibility = 'hidden'
  outer.style.width = '200px'
  outer.style.height = '150px'
  outer.style.overflow = 'hidden'
  outer.appendChild(inner)

  document.body.appendChild(outer)
  var w1 = inner.offsetWidth
  outer.style.overflow = 'scroll'
  var w2 = inner.offsetWidth
  if (w1 === w2) w2 = outer.clientWidth

  document.body.removeChild(outer)

  return w1 - w2
}

export const debugLog = (msg, ...args) => {
  if (!__log) return
  console.log(msg, ...args)
}

export const determineGaragePositionCaption = (elevation, uiConfig) => {
  let garagePos
  if (elevation && elevation.garagePosition && !elevation.mirrorElevationId) {
    if (elevation.garagePosition === 'LEFT') {
      if (uiConfig.mirror) {
        garagePos = 'Right'
      } else {
        garagePos = 'Left'
      }
    } else if (elevation.garagePosition === 'RIGHT') {
      if (uiConfig.mirror) {
        garagePos = 'Left'
      } else {
        garagePos = 'Right'
      }
    }
  }
  return garagePos
}

/**
 * This function is used to return a copy of elevationColors updated with
 * content from mirrorElevationColors. Use this function when your current
 * elevation doesn't have content (layers, schemes, materialPalettes, base)
 * and can re-use the content from the mirror elevation.
 * @param {*} elevationColors
 * @param {*} mirrorElevationColors
 */
export const applyMirrorContent = (elevationColors, mirrorElevationColors) => {
  let elevationColorsCopy = { ...elevationColors }
  // we could potentially have a stricter check
  if (!mirrorElevationColors || elevationColors.layers.length > 0) {
    return [elevationColorsCopy, false]
  }

  // borrow content from mirror elevation
  elevationColorsCopy.id = mirrorElevationColors.id
  elevationColorsCopy.thumb = mirrorElevationColors.thumb
  elevationColorsCopy.base = mirrorElevationColors.base
  elevationColorsCopy.layers = mirrorElevationColors.layers
  elevationColorsCopy.materialPalettes = mirrorElevationColors.materialPalettes
  if (elevationColors.schemes.length === 0) {
    elevationColorsCopy.schemes = mirrorElevationColors.schemes
  }
  return [elevationColorsCopy, true] // set to true, i.e. using mirror
}

export const mirrorElevationImage = (elevation, uiConfig) =>
  (elevation &&
    !elevation.mirrorElevationId &&
    !uiConfig.mirror &&
    elevation.garagePosition &&
    elevation.defaultGaragePosition &&
    elevation.garagePosition !== elevation.defaultGaragePosition) ||
  (elevation &&
    !elevation.mirrorElevationId &&
    uiConfig.mirror &&
    elevation.garagePosition === elevation.defaultGaragePosition)

// ugh...react-router#4683
export const withoutStaticContextProp = ({ staticContext, ...rest }) => ({
  ...rest,
})

const millisecondsInOneDay = 86400000

export const futureTimestamp = ({ daysOut = 0 } = {}) => {
  return Date.now() + daysOut * millisecondsInOneDay
}

export const daysUntil = (timestamp) => {
  return Math.ceil((timestamp - Date.now()) / millisecondsInOneDay)
}

export const formatCost = (value) =>
  value
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    .split('.')[0]

export const userIdentifier = (prospect, anonymousProspect) =>
  prospect ? `prospect-${prospect.id}` : anonymousProspect

export const getBaseElevations = (baseElevs) => {
  const elevations = baseElevs.filter((tgt) => {
    const { mirrorElevationId, mirrorRolePrimary } = tgt
    if (!mirrorElevationId) {
      return true
    } else if (mirrorRolePrimary) {
      return true
    }
    return false
  })
  return elevations
}

export const getDisclaimerText = (client) => {
  const { disclaimer } = client
  const tgtDisclaimer =
    disclaimer ||
    `${
      'Elevations of a home may vary and we reserve the right to substitute and /or ' +
      'modify design and materials, in our sole opinion and without notice. Please see ' +
      'your actual home purchase agreement for additional information, disclosures and ' +
      "disclaimers related to the home and its features. Plans are artist's renderings " +
      `and may contain options which are not standard on all models. ${client.name} reserves ` +
      'the right to make changes to plans and elevations without prior notice. Stated ' +
      'dimensions and square footage are approximate and should not be used as ' +
      "representation of the home's precise or actual size. Any statement, verbal or " +
      "written, regarding 'under air' or 'finished area' or any other description or " +
      'modifier of the square footage size of any home is a shorthand description of ' +
      'the manner in which the square footage was estimated and should not be construed ' +
      'to indicate certainty. Garage sizes may vary from home to home and may not accommodate all vehicles. '
    }${
      client.website == null
        ? 'See a New Home Consultant for further details and important legal disclaimers. ' +
          'This is not an offer in states where prior registration is required. Void where prohibited by law.'
        : `Visit ${client.website} or see a New Home Consultant for further details and important legal disclaimers. ` +
          'This is not an offer in states where prior registration is required. Void where prohibited by law.'
    }`
  return tgtDisclaimer
}

export const getInventoryImage = (
  inventory,
  elevation,
  directoryName,
  scaleImg = true
) => {
  const img =
    inventory && inventory.photos && inventory.photos.length > 0
      ? inventory.photos[0].src
      : null
  const scale = scaleImg ? `c_scale,h_${MAX_PLANCARD_MEDIA_HT}` : null
  let thumb =
    img !== null
      ? `${getAssetsPrefix(directoryName, scale)}/${
          inventory.photoFolder
        }/${img}`
      : null
  if (!thumb && elevation && elevation.thumb) {
    const prefix = getImagePrefix(directoryName, scale)
    thumb = `${prefix}/${elevation.thumb}`
  }
  return thumb
}

/**
 * Both selections and favorites sometimes need to infer a plan and elevation from
 * an inventory. In these cases, we should expect that the shape of the inventory
 * in question includes "plan" and "elevation" fields (even if their values are
 * falsy). This helper makes that check.
 */
export const inventoryIsValid = (inventory) => {
  const keys = Object.keys(inventory)
  return keys.includes('plan') && keys.includes('elevation')
}

/**
 * Helper to convert database constants, such as from enums, to displayable text.
 * Example: `prettifyString('UNDER_CONSTRUCTION')` equals "Under Construction".
 */
export const prettifyString = (context) => {
  const casedString =
    context.charAt(0).toUpperCase() + context.toLowerCase().slice(1)
  return casedString.replace(
    /_([a-zA-Z])/g,
    (match) => ` ${match[1].toUpperCase()}`
  )
}

// HOC class for wrapping with a plain component (such as a Context Provider)
// that doesn't need any props.
export const withComponent = (Component) => (OriginalComponent) => {
  const HoC = (props) => (
    <Component>
      <OriginalComponent {...props} />
    </Component>
  )
  return HoC
}

// The name of the injected prop is an option.
/*
export const withConsumer = (
  Consumer,
  { propName = 'contextValue' } = {}
) => OriginalComponent => {
  const HOC = props => (
    <Consumer>
      {// Inject the context value as a new prop in the original component.
      value => <OriginalComponent {...{ [propName]: value }} {...props} />}
    </Consumer>
  )
  return HOC
}
*/

// returns either white or black for a given color
// this function can be used to return a suitable text color to use given an
// input of a background color
export const getTextColor = (hex) => {
  const lum = chroma(hex).luminance()
  return lum > 0.7 ? '000000' : 'ffffff'
}

export const hexToRGB = (hex) => {
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return [r, g, b]
}

//
//
// SELECTIONS / FAVORITES

export const selectionLinkUrl = ({
  client,
  community,
  plan,
  elevation,
  lot,
  id,
  duplicateNumber,
}) => {
  if (!community) {
    // We don't want to allow selection linking for favorites with no community.
    // Favorites such as these can be added when the user likes a plan from the "View All
    // Plans" link.
    return null
  }

  return pipe(
    addPath('client', client.altName),
    addPath('community', community.name),
    apply(isInventoryLot)(lot)
      ? pipe(
          addPath('inventory'),
          addParam('inventoryId', lot.inventory.id),
          addParam('lotId', lot.id),
          id || duplicateNumber
            ? addParam('favoriteId', id || duplicateNumber)
            : identity
        )
      : pipe(
          plan ? addPath('plan', plan.name) : identity,
          elevation ? addParam('elevId', elevation.id) : identity,
          id || duplicateNumber
            ? addParam('favoriteId', id || duplicateNumber)
            : identity,
          lot ? addParam('lotId', lot.id) : identity
        ),
    getUrlFromQuery
  )(makeQuery())
}

export const isElevationExcluded = (
  planId,
  elevationId,
  excludedPlanElevations
) => {
  const selectedLotExludedElevations = excludedPlanElevations
  if (
    selectedLotExludedElevations &&
    selectedLotExludedElevations.length &&
    selectedLotExludedElevations.length > 0
  ) {
    const currentElevationFound = selectedLotExludedElevations.findIndex(
      (planElev) =>
        planElev.planId === planId && planElev.elevationId === elevationId
    )
    if (currentElevationFound >= 0) {
      return true
    }
  }
}

export const getInteriorEngineUrlAndSelections = (
  clientName,
  interior,
  interiorDesignSelections = []
) => {
  const interiorDesignSelection = interiorDesignSelections.filter(
    (item) => item.interiorId === interior.id
  )[0]
  let selectionsForInterior =
    interiorDesignSelection &&
    interiorDesignSelection.interiorSelections &&
    JSON.parse(JSON.stringify(interiorDesignSelection.interiorSelections))

  if (selectionsForInterior) {
    const keys = Object.keys(selectionsForInterior)
    keys.forEach((key) => {
      const selectionItem = selectionsForInterior[key]
      const parentId = selectionItem.mat.parentInteriorListElementId
      const parentElement = interior?.elementTrees?.find(
        (el) => el.id === parentId
      )
      if (parentElement && parentId) {
        selectionItem.mat['parentName'] = parentElement.name
      }
    })
  }
  if (!selectionsForInterior) {
    let engineUrl = `${cloudinaryPrefix}/w_300/app/${clientName}/images/${interior.thumbnailSrc}`
    return { engineUrl, selectionsForInterior }
  }

  const interiorId = interior.id

  let matsForHemi
  let optsForHemi
  let engineUrl
  matsForHemi =
    selectionsForInterior && Object.keys(selectionsForInterior).toString()
  optsForHemi =
    selectionsForInterior &&
    Object.values(selectionsForInterior)
      .map((val) => {
        return val.sel.id
      })
      .toString()
  engineUrl = `${process.env.REACT_APP_HEMI_ENGINE}/interior/client/${clientName}?interiorId=${interiorId}&mats=${matsForHemi}&opts=${optsForHemi}&key=123`

  return { engineUrl, selectionsForInterior }
}

export const shouldInventoryFlip = (inventory, elevation) => {
  if (!inventory || !elevation) {
    return false
  }
  if (inventory?.garagePosition && elevation?.svgMirroring) {
    if (
      elevation.garagePosition &&
      inventory.garagePosition !== elevation.defaultGaragePosition
    ) {
      return true
    } else if (
      !elevation.garagePosition &&
      inventory.garagePosition !== 'LEFT'
    ) {
      return true
    }
  }
  return false
}

export const isEmail = (email) => {
  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
  return emailRegEx.test(email)
}

export const isPhone = (phone) => {
  const phoneRegex = /^\([0-9]{3}\)[ ]{0,1}[0-9]{3}-[0-9]{4}$/
  return phoneRegex.test(phone)
}

export const formatPhone = (phone) => {
  return phone?.replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3')
}

export const isHomeSiteDisabled = (community) => {
  return (
    !community?.primarySiteplan?.src && !community?.primarySiteplan?.geoInfo
  )
}

export const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const generateSelectionTracking = (
  track,
  homeIdentifier,
  selection,
  mainAction,
  mainEvent,
  selectColorsEvent,
  selectFpOptsEvent,
  selectInteriorsEvent,
  selectInteriorOptsEvent
) => {
  const {
    community,
    plan,
    elevation,
    lot,
    scheme,
    colorSelections,
    interiorDesignSelections,
    fpOptSelections,
  } = selection

  // main  event
  if (mainAction) {
    const brochureProps = {
      homeIdentifier,
      communityId: community ? community.id : null,
      communityName: community ? community.name : null,
      planId: plan ? plan.id : null,
      planName: plan ? plan.name : null,
      elevationId: elevation ? elevation.id : null,
      elevationCaption: elevation ? elevation.caption : null,
      lotId: lot ? lot.id : null,
      lotName: lot ? lot.name : null,
      lotAddress: lot ? lot.address : null,
      inventoryId: lot ? lot?.inventory?.id : null,
      schemeId: scheme ? scheme.id : null,
      schemeName: scheme ? scheme.name : null,
      location: lot ? `${lot.cityName}, ${lot.stateCode}` : null,
      ...mainAction,
    }
    track(mainEvent, brochureProps)
  }

  let colorSelectionMaterials = Object.keys(colorSelections)
  // track selected colors
  if (colorSelections) {
    colorSelectionMaterials.forEach((materialId) => {
      const color = colorSelections[materialId]
      const colorTrackingProps = {
        communityId: community ? community.id : null,
        communityName: community ? community.name : null,
        planId: plan ? plan.id : null,
        planName: plan ? plan.name : null,
        elevationId: elevation ? elevation.id : null,
        elevationCaption: elevation ? elevation.caption : null,
        homeIdentifier,
        materialId,
        colorPaletteId: color.paletteId,
        colorName: color.name,
        colorType: color.type,
        colorMasonryLib: color.masonryLib,
        source: 'click',
        paletteSelectionId: color ? color.id : null,
        colorId: color ? color.colorId : null,
        brickId: color ? color.brickId : null,
        stoneId: color ? color.stoneId : null,
        customOverlayId: color ? color.customOverlayId : null,
      }
      track(selectColorsEvent, colorTrackingProps)
    })
  }

  if (scheme) {
    scheme.materials.forEach((color) => {
      if (colorSelectionMaterials.includes(color.materialId.toString())) return
      const color2 = {
        ...color,
        paletteId:
          color.paletteIds && color.paletteIds.length > 0
            ? color.paletteIds[0]
            : null,
      }
      const colorTrackingProps = {
        communityId: community ? community.id : null,
        communityName: community ? community.name : null,
        planId: plan ? plan.id : null,
        planName: plan ? plan.name : null,
        elevationId: elevation ? elevation.id : null,
        elevationCaption: elevation ? elevation.caption : null,
        homeIdentifier,
        materialId: color.materialId,
        colorPaletteId: color2.paletteId,
        colorName: color2.name,
        colorType: color2.type,
        colorMasonryLib: color2.masonryLib,
        source: 'scheme',
        schemeElementId: color ? color.id : null,
        colorId: color ? color.colorId : null,
        brickId: color ? color.brickId : null,
        stoneId: color ? color.stoneId : null,
        customOverlayId: color ? color.customOverlayId : null,
      }
      track(selectColorsEvent, colorTrackingProps)
    })
  }

  // track selected floorplan options
  if (fpOptSelections) {
    Object.keys(fpOptSelections).forEach((fnum) => {
      const fpOpts = fpOptSelections[fnum]
      fpOpts.forEach((fpOpt) => {
        const fpTrackingProps = {
          communityId: community ? community.id : null,
          communityName: community ? community.name : null,
          planId: plan ? plan.id : null,
          planName: plan ? plan.name : null,
          elevationId: elevation ? elevation.id : null,
          elevationCaption: elevation ? elevation.caption : null,
          homeIdentifier,
          floorplanOptionId: fpOpt.id,
          floorplanOptionName: fpOpt.name,
          floorplanOptionFnum: fpOpt.fnum,
        }
        track(selectFpOptsEvent, fpTrackingProps)
      })
    })
  }

  // track selected interiors
  if (interiorDesignSelections) {
    interiorDesignSelections.forEach((interior) => {
      const { interiorId, interiorSelections } = interior
      const interiorTrackingProps = {
        homeIdentifier,
        interiorId,
      }
      track(selectInteriorsEvent, interiorTrackingProps)
      if (interiorSelections && Object.keys(interiorSelections).length > 0) {
        Object.keys(interiorSelections).forEach((materialId) => {
          const choice = interiorSelections[materialId]
          const { elementId } = choice.sel
          const interiorSelectionProp = {
            communityId: community ? community.id : null,
            communityName: community ? community.name : null,
            planId: plan ? plan.id : null,
            planName: plan ? plan.name : null,
            elevationId: elevation ? elevation.id : null,
            elevationCaption: elevation ? elevation.caption : null,
            homeIdentifier,
            interiorId,
            elementId,
            selectionId: choice.sel.id,
            interiorListElementId: choice.sel.interiorListElementId,
          }
          track(selectInteriorOptsEvent, interiorSelectionProp)
        })
      }
    })
  }
}

export const promiseTuple = async (promise) => {
  try {
    const data = await promise
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

export const formatPrice = (price) => {
  if (isNaN(price)) {
    return null
  }
  return price
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    .split('.')[0]
}

export const isSelectionInventory = (selection) => {
  return (
    LotStatus.getInventoryStatuses().includes(selection?.lot?.salesStatus) &&
    selection?.lot?.inventory
  )
}

export const determineIfSelectionHasReservation = (
  onlineReservations,
  favoriteId
) => {
  if (!favoriteId || !onlineReservations?.length) {
    return false
  }
  const validReservation = onlineReservations.find(
    (reservation) => reservation.favoriteId === favoriteId
  )
  return !!validReservation
}

export const determineIfSelectionHasSignedReservation = (
  onlineReservations,
  favoriteId
) => {
  if (!favoriteId || !onlineReservations?.length) {
    return false
  }

  const validReservation = onlineReservations.find(
    (reservation) => reservation.favoriteId === favoriteId
  )
  return !!(
    validReservation &&
    validReservation.reservationStatus !== BuilderStatus.REJECTED_BY_BUILDER &&
    validReservation.reservationStatus !== BuilderStatus.REJECTED_BY_PROSPECT &&
    validReservation.signatureDate
  )
}

export const hasAnyProspectSignedFavoritesReservation = (
  onlineReservations,
  favoriteId
) => {
  const reservation = onlineReservations?.find(
    (res) => res.favoriteId === favoriteId
  )

  return (
    !!reservation?.signatureDate ||
    reservation?.secondaryBuyerReservations?.some((res) => !!res.signatureDate)
  )
}

export const getReservationStatus = (fav, prospect) => {
  const prospectReservationsCopy = prospect && [...prospect?.onlineReservations]
  const foundFavoriteReservation = prospectReservationsCopy?.find(
    (res) => res.favoriteId === fav.id
  )
  return foundFavoriteReservation
    ? foundFavoriteReservation.reservationStatus
    : null
}

export const timeout = (time) => {
  return new Promise((resolve) => window.setTimeout(resolve, time))
}

const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
]
const deca = [
  'twent',
  'thirt',
  'fort',
  'fift',
  'sixt',
  'sevent',
  'eight',
  'ninet',
]

export function stringifyNumber(n) {
  if (n < 20) return special[n]
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth'
  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10]
}

export const makeStringPGCompatible = (str) =>
  str ? encodeURIComponent(str).replace(/'/g, '%27') : ''

export const getSubSiteplanName = (selectedLot, selectedSiteplan) => {
  const selectedSiteplanId = selectedLot?.siteplanInfo?.siteplanId
  const masterSiteplanId = selectedSiteplan?.id
  let subSiteplanName = ''

  if (
    selectedSiteplanId &&
    masterSiteplanId &&
    selectedSiteplanId !== masterSiteplanId
  ) {
    subSiteplanName =
      selectedLot?.siteplanDisplayName || selectedLot?.siteplanName
  }

  return subSiteplanName
}
