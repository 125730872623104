// Common
export const EVT_USER_ALIAS = {
  name: 'User Alias',
  schema: 'user-alias-event',
  action: 'EVT_USER_ALIAS',
  version: '1-0-1',
}
export const EVT_CLICKED_SIGN_IN = {
  name: 'Clicked Sign In',
  schema: 'sign-in-event',
  action: 'EVT_CLICKED_SIGN_IN',
  version: '1-0-0',
}
export const EVT_CLICKED_START_OVER = {
  name: 'Clicked Start Over',
  schema: 'click-event',
  action: 'EVT_CLICKED_START_OVER',
  version: '1-0-2',
}
export const EVT_TOGGLED_FAVORITES_MENU = {
  name: 'Toggled Favorites Menu',
  schema: 'toggle-event',
  action: 'EVT_TOGGLED_FAVORITES_MENU',
  version: '1-0-1',
}
export const EVT_CLICKED_HEADER_TAB = {
  name: 'Clicked Header Tab',
  schema: 'click-event',
  action: 'EVT_CLICKED_HEADER_TAB',
  version: '1-0-2',
}
export const EVT_LINKED_TO_FAVORITE = {
  name: 'Linked To Favorite',
  schema: 'favorite-event',
  action: 'EVT_LINKED_TO_FAVORITE',
  version: '1-0-1',
}
export const EVT_DELETED_FAVORITE = {
  name: 'Deleted Favorite',
  schema: 'favorite-event',
  action: 'EVT_DELETED_FAVORITE',
  version: '1-0-1',
}
export const EVT_TOGGLED_COMPARE_FAVORITES = {
  name: 'Toggled Compare Favorites',
  schema: 'toggle-event',
  action: 'EVT_TOGGLED_COMPARE_FAVORITES',
  version: '1-0-1',
}
export const EVT_CLICKED_CONTACT_US = {
  name: 'Clicked Contact Us',
  schema: 'contact-event',
  action: 'EVT_CLICKED_CONTACT_US',
  version: '1-0-3',
}

// Interactive Floorplan events
export const EVT_CLICKED_FLOORPLAN_OPTION = {
  name: 'Clicked Floorplan Option',
  schema: 'interactive-floorplan-event',
  action: 'EVT_CLICKED_FLOORPLAN_OPTION',
  version: '1-0-1',
}
// Interactive Exterior events
export const EVT_CLICKED_PALETTE_COLOR = {
  name: 'Clicked Palette Color',
  schema: 'interactive-exterior-event',
  action: 'EVT_CLICKED_PALETTE_COLOR',
  version: '1-0-3',
}
export const EVT_CLICKED_COLOR_SCHEME = {
  name: 'Clicked Color Scheme',
  schema: 'interactive-exterior-event',
  action: 'EVT_CLICKED_COLOR_SCHEME',
  version: '1-0-3',
}
export const EVT_CLICKED_RESET_COLOR_SCHEME = {
  name: 'Clicked Reset Color Scheme',
  schema: 'interactive-exterior-event',
  action: 'EVT_CLICKED_RESET_COLOR_SCHEME',
  version: '1-0-3',
}

// Communities page (landing page)
export const EVT_TOGGLED_COMMUNITY_SELECTION = {
  name: 'Toggled Community Selection',
  schema: 'community-event',
  action: 'EVT_TOGGLED_COMMUNITY_SELECTION',
  version: '1-0-2',
}
export const EVT_CLICKED_COMMUNITY_QUICK_LINK = {
  name: 'Clicked Community Quick Link',
  schema: 'community-event',
  action: 'EVT_CLICKED_COMMUNITY_QUICK_LINK',
  version: '1-0-2',
}
export const EVT_CHANGED_COMMUNITIES_FILTER = {
  name: 'Changed Communities Filter',
  schema: 'filter-event',
  action: 'EVT_CHANGED_COMMUNITIES_FILTER',
  version: '1-0-2',
}
export const EVT_CLICKED_VIEW_PLANS = {
  name: 'Clicked View Plans',
  schema: 'click-event',
  action: 'EVT_CLICKED_VIEW_PLANS',
  version: '1-0-2',
}
export const EVT_LOCATION_INTEREST = {
  name: 'Location Interest',
  schema: 'location-interest-event',
  action: 'EVT_LOCATION_INTEREST',
  version: '1-0-0',
}

// Plans page (active community filters can be inferred from the route)
export const EVT_CHANGED_PLANS_FILTER = {
  name: 'Changed Plans Filter',
  schema: 'filter-event',
  action: 'EVT_CHANGED_PLANS_FILTER',
  version: '1-0-2',
}
export const EVT_FILTER_PLAN_TAGS = {
  name: 'Filter Plan Tags',
  schema: 'filter-event',
  action: 'EVT_FILTER_PLAN_TAGS',
  version: '1-0-2',
}
export const EVT_CLICKED_RESERVE_HOME_BUTTON = {
  name: 'Clicked Reserve Home Button',
  schema: 'reserve-home-event',
  action: 'EVT_CLICKED_RESERVE_HOME_BUTTON',
  version: '1-0-0',
}
export const EVT_CLICKED_PLANS_TAB = {
  name: 'Clicked Plans Tab',
  schema: 'click-event',
  action: 'EVT_CLICKED_PLANS_TAB',
  version: '1-0-2',
}
export const EVT_LIKED_HOME = {
  name: 'Liked Home',
  schema: 'like-event',
  action: 'EVT_LIKED_HOME',
  version: '1-0-1',
}
export const EVT_CLICKED_PLAN_CARD = {
  name: 'Clicked Plan Card',
  schema: 'clicked-card-event',
  action: 'EVT_CLICKED_PLAN_CARD',
  version: '1-0-0',
}
export const EVT_CLICKED_QMI_CARD = {
  name: 'Clicked QMI Card',
  schema: 'clicked-card-event',
  action: 'EVT_CLICKED_QMI_CARD',
  version: '1-0-0',
}
export const EVT_CLICKED_REQUEST_SGT_APPOINTMENT = {
  name: 'Clicked Request SGT Appointment',
  schema: 'request-sgt-event',
  action: 'EVT_CLICKED_REQUEST_SGT_APPOINTMENT',
  version: '1-0-0',
}

export const EVT_SUBMITTED_REQUEST_SGT_APPOINTMENT_FORM = {
  name: 'Submitted Request SGT Appointment Form',
  schema: 'submit-request-sgt-event',
  action: 'EVT_SUBMITTED_REQUEST_SGT_APPOINTMENT_FORM',
  version: '1-0-1',
}
export const EVT_CLICKED_ELEVATION_FROM_PLAN_CARD = {
  name: 'Clicked Elevation From Plan Card',
  schema: 'clicked-card-event',
  action: 'EVT_CLICKED_ELEVATION_FROM_PLAN_CARD',
  version: '1-0-0',
}
export const EVT_CLICKED_COMMUNITY_FROM_PLAN_CARD = {
  name: 'Clicked Community From Plan Card',
  schema: 'clicked-card-event',
  action: 'EVT_CLICKED_COMMUNITY_FROM_PLAN_CARD',
  version: '1-0-0',
}
export const EVT_HOME_INTEREST = {
  name: 'Home Interest Event',
  schema: 'home-interest-event',
  action: 'EVT_HOME_INTEREST',
  version: '1-0-0',
}

// Community info page
export const EVT_CLICKED_COMMUNITY_POIS = {
  name: 'Clicked Community POIs',
  schema: 'community-event',
  action: 'EVT_CLICKED_COMMUNITY_POIS',
  version: '1-0-2',
}

// Floorplan page
export const EVT_CLICKED_CREATE_MY_BROCHURE = {
  name: 'Clicked Create My Brochure',
  schema: 'brochure-event',
  action: 'EVT_CLICKED_CREATE_MY_BROCHURE',
  version: '1-0-2',
}
export const EVT_MY_BROCHURE_SELECTED_COLORS = {
  name: 'My Brochure Selected Colors',
  schema: 'brochure-selected-colors-event',
  action: 'EVT_MY_BROCHURE_SELECTED_COLORS',
  version: '1-0-4',
}
export const EVT_MY_BROCHURE_SELECTED_FPOPTS = {
  name: 'My Brochure Selected FpOpts',
  schema: 'brochure-selected-fp-event',
  action: 'EVT_MY_BROCHURE_SELECTED_FPOPTS',
  version: '1-0-2',
}
export const EVT_MY_BROCHURE_SELECTED_INTERIORS = {
  name: 'My Brochure Selected Interiors',
  schema: 'brochure-selected-interiors-event',
  action: 'EVT_MY_BROCHURE_SELECTED_INTERIORS',
  version: '1-0-1',
}

export const EVT_MY_BROCHURE_INTERIOR_SELECTIONS = {
  name: 'My Brochure Interior Selections',
  schema: 'brochure-interior-selections-event',
  action: 'EVT_MY_BROCHURE_INTERIOR_SELECTIONS',
  version: '1-0-1',
}

// My Home page
export const EVT_CLICKED_MY_HOME_CTA = {
  name: 'Clicked My Home CTA',
  schema: 'cta-event',
  action: 'EVT_CLICKED_MY_HOME_CTA',
  version: '1-0-1',
}
export const EVT_MY_HOME_CTA_SELECTED_COLORS = {
  name: 'My Home CTA Selected Colors',
  schema: 'my-home-cta-selected-colors-event',
  action: 'EVT_MY_HOME_CTA_SELECTED_COLORS',
  version: '1-0-3',
}
export const EVT_MY_HOME_CTA_SELECTED_FPOPTS = {
  name: 'My Home CTA Selected FpOpts',
  schema: 'my-home-cta-selected-fpopts-event',
  action: 'EVT_MY_HOME_CTA_SELECTED_FPOPTS',
  version: '1-0-1',
}
export const EVT_MY_HOME_CTA_SELECTED_INTERIORS = {
  name: 'My Home CTA Selected Interiors',
  schema: 'my-home-cta-selected-interiors-event',
  action: 'EVT_MY_HOME_CTA_SELECTED_INTERIORS',
  version: '1-0-2',
}

export const EVT_MY_HOME_CTA_INTERIOR_SELECTIONS = {
  name: 'My Home CTA Interior Selections',
  schema: 'my-home-cta-selected-interiors-event',
  action: 'EVT_MY_HOME_CTA_INTERIOR_SELECTIONS',
  version: '1-0-2',
}

//Mortgage Calculator
export const EVT_OPENED_MORTGAGE_CALCULATOR = {
  name: 'Opened Mortgage Calculator',
  schema: 'mortgage-calculator-event',
  action: 'EVT_OPENED_MORTGAGE_CALCULATOR',
  version: '1-0-1',
}
export const EVT_CLOSED_MORTGAGE_CALCULATOR = {
  name: 'Closed Mortgage Calculator',
  schema: 'mortgage-calculator-event',
  action: 'EVT_CLOSED_MORTGAGE_CALCULATOR',
  version: '1-0-1',
}
export const EVT_CHANGED_MORTGAGE_CALCULATOR_VALUE = {
  name: 'Changed Mortgage Calculator Value',
  schema: 'mortgage-calculator-event',
  action: 'EVT_CHANGED_MORTGAGE_CALCULATOR_VALUE',
  version: '1-0-1',
}

//Clicked Image
export const EVT_CLICKED_IMAGE = {
  name: 'Clicked Image',
  schema: 'clicked-image-event',
  action: 'EVT_CLICKED_IMAGE',
  version: '1-0-0',
}
export const EVT_CLICKED_VIEW_VIRTUAL_TOUR = {
  name: 'Clicked View Virtual Tour',
  schema: 'virtual-tour-event',
  action: 'EVT_CLICKED_VIEW_VIRTUAL_TOUR',
  version: '1-0-0',
}

//View Elevation and Inventory Pages
export const EVT_VIEW_ELEVATION_PAGE = {
  name: 'View Elevation Page',
  schema: 'page-view-event',
  action: 'EVT_VIEW_ELEVATION_PAGE',
  version: '1-0-1',
}
export const EVT_VIEW_INVENTORY_PAGE = {
  name: 'View Inventory Page',
  schema: 'page-view-event',
  action: 'EVT_VIEW_INVENTORY_PAGE',
  version: '1-0-1',
}

//Favorites Page
export const EVT_PROSPECT_SHARED_FAVORITE = {
  name: 'Prospect Shared Favorite',
  schema: 'shared-favorite-event',
  action: 'EVT_PROSPECT_SHARED_FAVORITE',
  version: '1-0-0',
}
export const EVT_PROSPECT_SHARED_FAVORITE_WITH = {
  name: 'Prospect Shared Favorite With',
  schema: 'shared-favorite-event',
  action: 'EVT_PROSPECT_SHARED_FAVORITE_WITH',
  version: '1-0-0',
}

// Buy now
export const EVT_CLICKED_BUY_NOW_BUTTON = {
  name: 'Clicked buy now button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_BUY_NOW_BUTTON',
  version: '1-0-1',
}
export const EVT_CLICKED_CANCEL_RESERVATION_BUTTON_AT_RESERVE_PAGE = {
  name: 'Clicked cancel reservation button at reserve page',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_CANCEL_RESERVATION_BUTTON_AT_RESERVE_PAGE',
  version: '1-0-1',
}

export const EVT_CLICKED_PROCEED_RESERVATION_BUTTON = {
  name: 'Clicked proceed reservation button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_PROCEED_RESERVATION_BUTTON',
  version: '1-0-1',
}

export const EVT_CLICKED_FINISH_RESERVATION_BUTTON = {
  name: 'Clicked finish reservation button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_FINISH_RESERVATION_BUTTON',
  version: '1-0-1',
}

export const EVT_CLICKED_ADD_BUYER_RESERVATION_BUTTON = {
  name: 'Clicked add buyer reservation button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_ADD_BUYER_RESERVATION_BUTTON',
  version: '1-0-1',
}

export const EVT_CLICKED_REMOVE_BUYER_RESERVATION_BUTTON = {
  name: 'Clicked remove buyer reservation button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_REMOVE_BUYER_RESERVATION_BUTTON',
  version: '1-0-1',
}

export const EVT_SWITCH_BETWEEN_BUYERS_IN_RESERVATION_DIALOG = {
  name: 'Clicked switch between buyers in reservation button',
  schema: 'buy-online-event',
  action: 'EVT_SWITCH_BETWEEN_BUYERS_IN_RESERVATION_DIALOG',
  version: '1-0-1',
}

export const EVT_CLICKED_CANCEL_BUY_ONLINE_DIALOG_BUTTON = {
  name: 'Clicked cancel buy online dialog button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_CANCEL_BUY_ONLINE_DIALOG_BUTTON',
  version: '1-0-1',
}

export const EVT_CLICKED_SUBMIT_BUY_ONLINE_DIALOG_BUTTON = {
  name: 'Clicked submit buy online dialog button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_SUBMIT_BUY_ONLINE_DIALOG_BUTTON',
  version: '1-0-1',
}

export const EVT_CLICKED_MY_HOME_PAGE_RESERVATION_BUTTON = {
  name: 'Clicked my home page reservation button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_MY_HOME_PAGE_RESERVATION_BUTTON',
  version: '1-0-1',
}

export const EVT_CLICKED_CANCEL_RESERVATION_BUTTON_AT_RESERVATION_PAGE = {
  name: 'Clicked cancel reservation button at reservation page',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_CANCEL_RESERVATION_BUTTON_AT_RESERVATION_PAGE',
  version: '1-0-1',
}

export const EVT_CLICKED_CANCEL_BUTTON_IN_CANCEL_RESERVATION_DIALOG = {
  name: 'Clicked cancel reservation button in reservation dialog',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_CANCEL_BUTTON_IN_CANCEL_RESERVATION_DIALOG',
  version: '1-0-1',
}

export const EVT_CLICKED_CONFIRM_AND_CONTINUE_BUTTON_IN_CANCEL_RESERVATION_DIALOG =
  {
    name: 'Clicked confirm and continue reservation button in reservation dialog',
    schema: 'buy-online-event',
    action:
      'EVT_CLICKED_CONFIRM_AND_CONTINUE_BUTTON_IN_CANCEL_RESERVATION_DIALOG',
    version: '1-0-1',
  }

export const EVT_CLICKED_RESERVATION_TERMS_AND_CONDITIONS_LINK = {
  name: 'Clicked reservation terms and conditions link',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_RESERVATION_TERMS_AND_CONDITIONS_LINK',
  version: '1-0-1',
}

export const EVT_CLICKED_ACCEPT_RESERVATION_TERMS_AND_CONDITIONS_BUTTON = {
  name: 'Clicked accept reservation terms and conditions button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_ACCEPT_RESERVATION_TERMS_AND_CONDITIONS_BUTTON',
  version: '1-0-1',
}

export const EVT_CLICKED_SIGN_DOCUMENT_RESERVATION_BUTTON = {
  name: 'Clicked sign document reservation button',
  schema: 'sign-reservation-event',
  action: 'EVT_CLICKED_SIGN_DOCUMENT_RESERVATION_BUTTON',
  version: '1-0-1',
}

export const EVT_CLICKED_CANCEL_BUTTON_IN_CONFIRM_CONTACT_INFORMATION_DIALOG = {
  name: 'Clicked cancel button in confirm contact information dialog',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_CANCEL_BUTTON_IN_CONFIRM_CONTACT_INFORMATION_DIALOG',
  version: '1-0-1',
}

export const EVT_CLICKED_CONFIRM_AND_CONTINUE_BUTTON_IN_CONFIRM_CONTACT_INFORMATION_DIALOG =
  {
    name: 'Clicked confirm and continue button in confirm contact information dialog',
    schema: 'buy-online-event',
    action:
      'EVT_CLICKED_CONFIRM_AND_CONTINUE_BUTTON_IN_CONFIRM_CONTACT_INFORMATION_DIALOG',
    version: '1-0-1',
  }

export const EVT_RESERVATION_DOCUMENT_SIGNED = {
  name: 'Reservation document signed',
  schema: 'buy-online-event',
  action: 'EVT_RESERVATION_DOCUMENT_SIGNED',
  version: '1-0-1',
}
export const EVT_RESERVATION_DOCUMENT_DECLINED = {
  name: 'Reservation document rejected',
  schema: 'buy-online-event',
  action: 'EVT_RESERVATION_DOCUMENT_DECLINED',
  version: '1-0-1',
}
export const EVT_CLICKED_MAKE_PAYMENT_RESERVATION_BUTTON = {
  name: 'Clicked make payment reservation button',
  schema: 'buy-online-event',
  action: 'EVT_CLICKED_MAKE_PAYMENT_RESERVATION_BUTTON',
  version: '1-0-1',
}

export const EVT_RESERVATION_DEPOSIT_PAID = {
  name: 'Reservation deposit paid',
  schema: 'buy-online-event',
  action: 'EVT_RESERVATION_DEPOSIT_PAID',
  version: '1-0-1',
}
export const EVT_RESERVATION_DEPOSIT_UNPAID = {
  name: 'Reservation deposit unpaid',
  schema: 'buy-online-event',
  action: 'EVT_RESERVATION_DEPOSIT_UNPAID',
  version: '1-0-1',
}
export const EVT_LEAVE_RESERVATION_PAGE = {
  name: 'Leave reservation page',
  schema: 'buy-online-event',
  action: 'EVT_LEAVE_RESERVATION_PAGE',
  version: '1-0-1',
}
