import gql from 'graphql-tag'
import * as fragments from './fragments'
import * as commonFields from './commonFields'
import DisabledErrorModalOperations from '../utils/disabledErrorModalOperations'

//
//
// MUTATIONS
//

export const CREATE_NEW_RESERVATION = gql`
  mutation ${DisabledErrorModalOperations.CreateNewReservation}(
    $clientName: String!
    $favoriteId: Int!
    $buyer: PrimaryBuyerInput!
    $additionalBuyer: AdditionalBuyerInput
    $totalPrice: Int!
  ) {
    createNewReservation(
      clientName: $clientName
      favoriteId: $favoriteId
      buyer: $buyer
      additionalBuyer: $additionalBuyer
      totalPrice: $totalPrice
    )
  }
`

export const ADD_ADDITIONAL_BUYER = gql`
  mutation ${DisabledErrorModalOperations.AddAdditionalBuyerForReservation}(
    $clientName: String!
    $token: JWT!
  ) {
    addAdditionalBuyerForReservation(clientName: $clientName, token: $token)
  }
`

export const ADD_HOME_SELECTION = gql`
  mutation AddHomeSelection($clientName: String!, $input: HomeSelectionInput!) {
    addHomeSelection(clientName: $clientName, input: $input)
  }
`

export const AUTHENTICATE_API_KEY = gql`
  query AuthenticateApiKey($apiKey: String!) {
    authenticateApiKey(apiKey: $apiKey)
  }
`

export const UPDATE_PROSPECT_PICTURE = gql`
  mutation UpdateProspectPicture($prospectEmail: String!, $fileName: String!) {
    updateProspectPicture(prospectEmail: $prospectEmail, fileName: $fileName)
  }
`

export const ADD_AGENT_PROSPECT = gql`
  mutation addAgentProspect(
    $prospectEmail: String!
    $agent: UpsertAgentInput!
    $clientName: String!
    $source: String!
  ) {
    addAgentProspect(
      prospectEmail: $prospectEmail
      agent: $agent
      clientName: $clientName
      source: $source
    )
  }
`

export const SEND_REGISTRATION_EMAIL = gql`
  mutation SendProspectRegistrationEmail(
    $clientName: String!
    $prospectId: Int!
    $input: ProspectInput
  ) {
    sendProspectRegistrationEmail(
      clientName: $clientName
      prospectId: $prospectId
      source: "truss"
      input: $input
    )
  }
`

export const SEND_WELCOME_EMAIL = gql`
  mutation SendWelcomeEmail(
    $clientName: String!
    $prospectId: Int!
    $communityId: Int
    $agentEmail: String
    $myHomeAppLink: String!
  ) {
    sendWelcomeEmail(
      clientName: $clientName
      prospectId: $prospectId
      communityId: $communityId
      agentEmail: $agentEmail
      myHomeAppLink: $myHomeAppLink
    )
  }
`

export const UPSERT_PROSPECT = gql`
  mutation UpsertProspect($clientName: String!, $input: ProspectInput!) {
    upsertProspect(clientName: $clientName, input: $input) {
      id
      email
      name
      firstName
      lastName
      address
      city
      state
      zipCode
      country
      picture
      source
      phone
      preferredContactMethods
      brochures {
        url
        creationDate
      }
      registrationDate
      consultantName
      consultantEmail
      consultantPhone
      communityId
      communityExternalId
      planId
      elevationId
      schemeId
      lotId
      lotExternalId
      inventoryId
      prospectEmailExists
      receivedFavorites {
        sentByProspectName
        sentByProspectEmail
        sentByProspectId
        receivedByProspectName
        receivedByProspectEmail
        clientId
        clientAltName
      }
      favorites {
        id
        clientName
        communityId
        planId
        elevationId
        lotId
        schemeId
        colorSelections
        fpOptSelections
        interiorDesignSelections
        garagePosition
        isMirrored
        scheme(clientName: $clientName) {
          id
          name
          materials(clientName: $clientName) {
            id
            materialId
            materialCategory
            material
            name
            hex
            type
            swatch
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
            paletteIds(clientName: $clientName)
          }
        }
        community(clientName: $clientName) {
          active
          id
          name
          pricing
          agents(clientName: $clientName) {
            id
            email
          }
        }
        plan(clientName: $clientName) {
          id
          communityId
          name
          displayName
        }
        elevation(clientName: $clientName) {
          active
          id
          communityId
          planName
          planDisplayName
          planId
          caption
          thumb
          bed
          bath
          size
          cost
          garagePosition
          defaultGaragePosition
          svgMirroring
        }
        lot(clientName: $clientName) {
          id
          communityId
          name
          salesStatus
          premium
          externalId
          address
          size
          geoJson
          availableSchemes(clientName: $clientName) {
            id
            name
          }
          inventory(clientName: $clientName) {
            id
            lotId
            planId
            elevationId
            communityId
            price
            sqft
            beds
            baths
            photoFolder
            photos(clientName: $clientName) {
              id
              src
              listIndex
            }
          }
        }
      }
      onlineReservations {
        id
        prospectReservationId
        favoriteId
        reservationStatus
        signatureDate
        secondaryBuyerReservations {
          signatureDate
        }
      }
    }
  }
`
export const ADD_PROSPECT_FAVORITES = gql`
  mutation AddPropsectFavorites(
    $clientName: String!
    $email: String!
    $favorites: [ProspectFavoriteInput!]!
  ) {
    addFavoritesForProspect(
      clientName: $clientName
      email: $email
      favorites: $favorites
    )
  }
`

export const UPDATE_PROSPECT_FAVORITES = gql`
  mutation UpdateProspectFavorites(
    $clientName: String!
    $input: [ProspectFavoriteInput!]!
  ) {
    updateFavoritesForProspect(clientName: $clientName, input: $input)
  }
`

export const DELETE_PROSPECT_FAVORITE = gql`
  mutation DeletePropsectFavorite($clientName: String!, $id: Int!) {
    deleteFavoriteForProspect(clientName: $clientName, id: $id)
  }
`

export const REPLACE_PROSPECT_FAVORITES = gql`
  mutation ReplaceProspectFavorites(
    $clientName: String!
    $email: String!
    $favorites: [ProspectFavoriteInput!]!
  ) {
    replaceFavoritesForProspect(
      clientName: $clientName
      email: $email
      favorites: $favorites
    ) {
      id
      clientName
      communityId
      planId
      elevationId
      lotId
      schemeId
      colorSelections
      fpOptSelections
      interiorDesignSelections
      source
      garagePosition
      isMirrored
      scheme(clientName: $clientName) {
        id
        name
        materials(clientName: $clientName) {
          id
          materialId
          materialCategory
          material
          name
          hex
          type
          swatch
          masonryLib
          colorId
          brickId
          stoneId
          customOverlayId
          paletteIds(clientName: $clientName)
        }
      }
      community(clientName: $clientName) {
        active
        id
        name
        pricing
        agents(clientName: $clientName) {
          id
          email
        }
        primarySiteplan(clientName: $clientName, active: true) {
          id
          lotMetric
          lotLegend(clientName: $clientName) {
            id
            code
            name
            hex
          }
        }
      }
      plan(clientName: $clientName) {
        id
        communityId
        name
      }
      elevation(clientName: $clientName) {
        active
        id
        communityId
        planName
        planDisplayName
        planId
        caption
        thumb
        bed
        bath
        size
        cost
        garagePosition
        defaultGaragePosition
      }
      lot(clientName: $clientName) {
        id
        communityId
        name
        salesStatus
        premium
        externalId
        address
        size
        geoJson
        availableSchemes(clientName: $clientName) {
          id
          name
        }
        inventory(clientName: $clientName) {
          id
          lotId
          planId
          elevationId
          communityId
          price
          sqft
          beds
          baths
          photoFolder
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
        }
      }
    }
  }
`

export const VALIDATE_IDENTITY = gql`
  query validateIdentity(
    $accessToken: String!
    $identityProvider: IdentityProvider!
  ) {
    validateIdentity(
      accessToken: $accessToken
      identityProvider: $identityProvider
    ) {
      flagshipToken
      tokenExp
      email
      id
    }
  }
`

export const VALIDATE_TOKEN = gql`
  query validateProspectToken(
    $token: String!
    $clientName: String!
    $hasConsent: Boolean!
  ) {
    validateProspectToken(
      token: $token
      clientName: $clientName
      hasConsent: $hasConsent
    ) {
      isValid
      isRegistered
      isFirstSignIn
    }
  }
`

export const AUTHENTICATE_PROSPECT = gql`
  query authenticateProspect(
    $clientName: String!
    $source: String!
    $accessToken: String!
    $identityProvider: IdentityProvider!
    $communityId: Int
    $planName: String
    $consultantName: String
    $consultantEmail: String
    $consultantPhone: String
  ) {
    authenticateProspect(
      clientName: $clientName
      source: $source
      accessToken: $accessToken
      identityProvider: $identityProvider
      communityId: $communityId
      planName: $planName
      consultantName: $consultantName
      consultantEmail: $consultantEmail
      consultantPhone: $consultantPhone
    ) {
      flagshipToken
      tokenExp
      isRegistered
      isFirstSignIn
      prospect {
        id
        email
        name
        firstName
        lastName
        address
        city
        state
        zipCode
        country
        picture
        source
        phone
        preferredContactMethods
        brochures {
          url
          creationDate
        }
        registrationDate
        consultantName
        consultantEmail
        consultantPhone
        communityId
        communityExternalId
        planId
        elevationId
        schemeId
        lotId
        lotExternalId
        inventoryId
        prospectEmailExists
        receivedFavorites {
          sentByProspectName
          sentByProspectEmail
          sentByProspectId
          receivedByProspectName
          receivedByProspectEmail
          clientId
          clientAltName
        }
        favorites {
          id
          clientName
          communityId
          planId
          elevationId
          lotId
          schemeId
          colorSelections
          fpOptSelections
          interiorDesignSelections
          garagePosition
          isMirrored
          lastUpdate
          scheme(clientName: $clientName) {
            id
            name
            materials(clientName: $clientName) {
              id
              materialId
              materialCategory
              material
              name
              hex
              type
              swatch
              masonryLib
              colorId
              brickId
              stoneId
              customOverlayId
              paletteIds(clientName: $clientName)
            }
          }
          community(clientName: $clientName) {
            id
            active
            name
            pricing
            agents(clientName: $clientName) {
              id
              email
            }
          }
          plan(clientName: $clientName) {
            id
            communityId
            name
            displayName
          }
          elevation(clientName: $clientName) {
            id
            active
            communityId
            planName
            planDisplayName
            planId
            caption
            thumb
            bed
            bath
            size
            cost
            garagePosition
            defaultGaragePosition
            svgMirroring
          }
          lot(clientName: $clientName) {
            id
            communityId
            name
            salesStatus
            premium
            externalId
            address
            size
            geoJson
            availableSchemes(clientName: $clientName) {
              id
              name
            }
            inventory(clientName: $clientName) {
              id
              lotId
              planId
              elevationId
              communityId
              price
              sqft
              beds
              baths
              photoFolder
              photos(clientName: $clientName) {
                id
                src
                listIndex
              }
            }
          }
        }
        onlineReservations {
          id
          prospectReservationId
          favoriteId
          reservationStatus
          signatureDate
          secondaryBuyerReservations {
            signatureDate
          }
        }
      }
    }
  }
`

export const GET_PROSPECT_UPDATES = gql`
  query prospectById($clientName: String!, $id: Int!) {
    prospectById(id: $id, clientName: $clientName) {
      brochures {
        url
        creationDate
      }
      receivedFavorites {
        sentByProspectName
        sentByProspectEmail
        sentByProspectId
        receivedByProspectName
        receivedByProspectEmail
        clientId
        clientAltName
      }
      favorites {
        id
        clientName
        communityId
        planId
        elevationId
        lotId
        schemeId
        colorSelections
        fpOptSelections
        lastUpdate
        interiorDesignSelections
        garagePosition
        isMirrored
        mortgage {
          downPayment
          downPercent
          interest
          term
        }
        scheme(clientName: $clientName) {
          id
          name
          materials(clientName: $clientName) {
            id
            materialId
            materialCategory
            material
            name
            hex
            type
            swatch
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
            paletteIds(clientName: $clientName)
          }
        }
        community(clientName: $clientName) {
          active
          id
          name
          pricing
          agents(clientName: $clientName) {
            id
            email
          }
        }
        plan(clientName: $clientName) {
          id
          communityId
          name
          displayName
        }
        elevation(clientName: $clientName) {
          active
          id
          communityId
          planName
          planDisplayName
          planId
          caption
          thumb
          bed
          bath
          size
          cost
          garagePosition
          defaultGaragePosition
          svgMirroring
        }
        lot(clientName: $clientName) {
          id
          communityId
          name
          salesStatus
          premium
          externalId
          address
          size
          geoJson
          availableSchemes(clientName: $clientName) {
            id
            name
          }
          inventory(clientName: $clientName) {
            id
            lotId
            planId
            elevationId
            communityId
            price
            sqft
            beds
            baths
            photoFolder
            photos(clientName: $clientName) {
              id
              src
              listIndex
            }
          }
        }
      }
      onlineReservations {
        id
        prospectReservationId
        favoriteId
        reservationStatus
        signatureDate
        secondaryBuyerReservations {
          signatureDate
        }
      }
    }
  }
`

export const ADD_RECEIVED_FAVORITES_LINK = gql`
  mutation AddProspectReceivedLink(
    $senderProspectId: Int!
    $receiverProspectId: Int!
    $clientId: Int!
  ) {
    addProspectReceivedLink(
      senderProspectId: $senderProspectId
      receiverProspectId: $receiverProspectId
      clientId: $clientId
    ) {
      sentByProspectName
      sentByProspectEmail
      sentByProspectId
      receivedByProspectName
      receivedByProspectEmail
      clientId
      clientAltName
    }
  }
`

export const UPDATE_PROSPECT_CONTACT_INFOS = gql`
  mutation updateProspectContactInfos(
    $id: Int!
    $phone: String!
    $preferredContactMethods: [ContactMethodType!]!
    $name: String
  ) {
    updateProspectContactInfos(
      id: $id
      phone: $phone
      preferredContactMethods: $preferredContactMethods
      name: $name
    ) {
      id
      name
      firstName
      lastName
    }
  }
`
export const REQUEST_SGT_APPOINTMENT = gql`
  mutation RequestSgtAppointments(
    $clientName: String!
    $prospectSgtApptInput: ProspectSgtApptInput!
    $timezoneOffset: Int!
  ) {
    requestSgtAppointment(
      clientName: $clientName
      prospectSgtApptInput: $prospectSgtApptInput
      timezoneOffset: $timezoneOffset
    ) {
      id
      prospectId
      inventoryId
      preferredDate
      createdOn
    }
  }
`

export const SEND_FAVORITES_EMAIL = gql`
  mutation sendShareFavoritesEmail(
    $prospectName: String!
    $prospectEmail: String!
    $friendsEmails: [String!]!
    $agentsEmails: [String!]
    $notes: String
    $preferredContactMethod: [ContactMethodType!]
    $clientName: String!
    $linkAddress: String!
    $clientLogoSrc: String
  ) {
    sendShareFavoritesEmail(
      prospectName: $prospectName
      prospectEmail: $prospectEmail
      friendsEmails: $friendsEmails
      agentsEmails: $agentsEmails
      notes: $notes
      preferredContactMethod: $preferredContactMethod
      clientName: $clientName
      linkAddress: $linkAddress
      clientLogoSrc: $clientLogoSrc
    )
  }
`

export const SEND_INQUIRY = gql`
  mutation SendInquiryToBuilder($clientName: String!, $input: InquiryInput!) {
    sendInquiryToBuilder(clientName: $clientName, input: $input)
  }
`

//
//
// Queries
//

export const GET_FAVORITE_BY_ID = gql`
  query getFavoriteById($clientName: String!, $favoriteId: Int!) {
    getFavoriteById(clientName: $clientName, favoriteId: $favoriteId) {
      id
      prospectId
      communityId
      lotId
      source
      clientName
      community(clientName: $clientName) {
        active
        id
        name
        pricing
        colormtd
      }
      elevation(clientName: $clientName) {
        active
        id
        planName
        planId
        communityId
        caption
        bed
        bath
        size
        cost
        thumb
        mirrorRolePrimary
        base
        layers(clientName: $clientName) {
          materialId
          name
          materialCategory
          src
        }
      }
      inventory(clientName: $clientName) {
        beds
        baths
        sqft
        price
        garagePosition
        elevation(clientName: $clientName) {
          id
          active
          planId
          communityId
          bed
          bath
          size
          cost
        }
      }
      plan(clientName: $clientName) {
        id
        communityId
        name
      }
      lot(clientName: $clientName) {
        id
        name
        salesStatus
        premium
        geoJson
        availableSchemes(clientName: $clientName) {
          id
          name
          internalName
          cost
        }
        inventory(clientName: $clientName) {
          id
          beds
          baths
          sqft
          price
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
          elevation(clientName: $clientName) {
            id
            active
            planId
            communityId
            svgMirroring
            garagePosition
          }
          exteriorConfiguration(clientName: $clientName) {
            inventoryId
            scheme {
              id
              cost
            }
          }
        }
      }
      scheme(clientName: $clientName) {
        id
        name
        cost
        materials(clientName: $clientName) {
          id
          materialId
          materialCategory
          material
          name
          hex
          type
          swatch
          masonryLib
          colorId
          brickId
          stoneId
          customOverlayId
        }
      }
      fpOptSelections
      interiorDesignSelections
      colorSelections
    }
  }
`

export const ALL_CLIENTS = gql`
  query getAllClients($statuses: [ClientStatus!], $kinds: [ClientType!]) {
    allClients(statuses: $statuses, kinds: $kinds) {
      id
      name
      altName
      logo
      directoryName
      website
    }
  }
`

export const BUILDER_APP_CONFIG = gql`
  query GetBuilderAppConfig($clientName: String!) {
    builderAppConfig(clientName: $clientName)
    clientCustomizations(clientName: $clientName) {
      id
      name
      value
    }
  }
`

export const CLIENT = gql`
  query GetClient($clientName: String!) {
    clientByName(clientName: $clientName) {
      id
      name
      altName
      directoryName
      disclaimer
      trackingId
      logo
      website
      contact
      email
      phone
      sgtVendors(clientName: $clientName) {
        id
        icon
        logo
        name
        extendedData
      }
    }
  }
`

export const CLIENT_STATUS_BY_NAME = gql`
  query GetClientStatus($clientName: String!) {
    clientByName(clientName: $clientName) {
      status
    }
  }
`

export const CLIENT_REQUIRES_REGISTRATION = gql`
  query RequireRegistration($clientName: String!) {
    customization(clientName: $clientName, name: "requireRegister") {
      id
      name
      value
    }
  }
`

export const EXTERIOR_TAGS = gql`
  query GetExteriorTags($clientName: String!) {
    exteriorTags(clientName: $clientName) {
      id
      name
    }
  }
`

export const COMMUNITIES_BY_IDS = gql`
  query GetCommunitiesByIds($clientName: String!, $communityIds: [Int!]) {
    communities(clientName: $clientName, communityIds: $communityIds) {
      id
      active
      name
      sortType
      sortOrder
      thumb
      pricing
      bonafide
      agents(clientName: $clientName) {
        id
        email
        phone
        firstName
        lastName
      }
      cityLocation(clientName: $clientName) {
        ...CityFields
      }
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        src
        lotLegend(clientName: $clientName) {
          id
          code
          name
        }
      }
    }
  }
  ${fragments.city}
`

export const COMMUNITY_BY_NAME = gql`
  query GetCommunityByName($clientName: String!, $communityName: String!) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      id
      active
      name
      sortType
      sortOrder
      thumb
      pricing
      bonafide
      agents(clientName: $clientName) {
        id
        email
        phone
        firstName
        lastName
      }
      cityLocation(clientName: $clientName) {
        ...CityFields
      }
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        src
        geoInfo(clientName: $clientName) {
          id
        }
        lotLegend(clientName: $clientName) {
          id
          code
          name
        }
      }
    }
  }
  ${fragments.city}
`

export const COMMUNITIES_ONLY = gql`
  query GetCommunitiesOnly($clientName: String!, $bonafide: Boolean) {
    communities(clientName: $clientName, active: true, bonafide: $bonafide) {
      id
      name
      logo
      longitude
      latitude
      thumb
      cityLocation(clientName: $clientName) {
        ...CityFields
      }
      lots(clientName: $clientName, quickMoveInOnly: true) {
        id
        salesStatus
        inventory(clientName: $clientName) {
          id
          price
          sqft
          beds
          baths
        }
      }
    }
  }
  ${fragments.city}
`

export const COMMUNITIES_LITE = gql`
  query GetCommunitiesLite($clientName: String!, $bonafide: Boolean) {
    communities(clientName: $clientName, active: true, bonafide: $bonafide) {
      id
      name
      logo
      longitude
      latitude
      thumb
      bonafide
      pricing
      agents(clientName: $clientName) {
        id
        email
        phone
        firstName
        lastName
      }
      cityLocation(clientName: $clientName) {
        ...CityFields
      }
      plans(clientName: $clientName, active: true) {
        id
        communityId
        name
        ${commonFields.ranges}
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        src
        siteplanType
        geoInfo(clientName: $clientName) {
          id
          siteplanId
          neLatitude
          neLongitude
          swLatitude
          swLongitude
          geoJson
        }
      }
      lots(clientName: $clientName, activeSiteplanOnly: true) {
        id
        salesStatus
        inventory(clientName: $clientName) {
          id
          price
          sqft
          beds
          baths
          elevationId
        }
      }
    }
  }
  ${fragments.city}
`

export const COMMUNITIES = gql`
  query GetCommunities($clientName: String!, $bonafide: Boolean) {
    communities(clientName: $clientName, active: true, bonafide: $bonafide) {
      ...CommunityFields
      plans(clientName: $clientName) {
        ...PlanFields
      }
      lots(clientName: $clientName, quickMoveInOnly: true) {
        ...LotFields
        inventory(clientName: $clientName) {
          ...InventoryFields
        }
      }
    }
  }
  ${fragments.community}
  ${fragments.plan}
  ${fragments.lot}
  ${fragments.inventory}
`

export const GET_PAGINATED_INVENTORY_LOTS = gql`
  query GetPaginatedInventoryLots(
    $clientName: String!
    $communityIds: [Int!]!
    $limit: Int!
    $offset: Int!
    $sortBy: String!
    $filter: SpecFilter
  ) {
    paginatedInventoryLots(
      clientName: $clientName
      communityIds: $communityIds
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      filter: $filter
    ) {
      cursor {
        offset
      }
      lots {
        id
        name
        address
        salesStatus
        communityId
        siteplanName(clientName: $clientName)
        inventory(clientName: $clientName) {
          ...InventoryFields
          virTourUrl
          elevation(clientName: $clientName) {
            id
            active
            planId
            planName
            communityId
            caption
            thumb
            bed
            bath
            size
          }
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
        }
      }
    }
  }
  ${fragments.inventory}
`

export const GET_PAGINATED_PLANS = gql`
  query GetPaginatedPlans($clientName: String!, $communityIds: [Int!], $limit: Int!, $offset: Int!, $sortBy: String!, $sortOrder: String!, $filter: SpecFilter) {
    paginatedPlans(clientName: $clientName, communityIds: $communityIds, limit: $limit, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder, filter: $filter) {
      cursor {
        offset
      }
      plans(communityIds: $communityIds) {
        ...PlanFields
        exteriorAssets(clientName: $clientName) {
          src
          listIndex
          videoSrc
        }
        interiorPhotos(clientName: $clientName) {
          src
          listIndex
        }
        elevations(clientName: $clientName, communityIds: $communityIds, active: true) {
          ...ElevationFields
            communities(clientName: $clientName, excludeDefaultCommunity: true, active: true){
                id
                name
                thumb
            }
          tags(clientName: $clientName) {
            categoryName
            tagName
          }
          stories(clientName: $clientName) {
            ${commonFields.story}
            floorplans(clientName: $clientName) {
              ${commonFields.floorplan}
            }
          }
        }
        communities(clientName: $clientName, filters: { active: true }) {
          id
        }
      }
    }
  }
  ${fragments.plan}
  ${fragments.elevation}
`

export const PAGINATED_PLANS_FILTERS = gql`
  query GetPaginatedPlansFilters($clientName: String!, $communityIds: [Int!]!) {
    paginatedPlansFilters(
      clientName: $clientName
      communityIds: $communityIds
    ) {
      availableStories
    }
  }
`

export const GET_PLAN_TAGS_BY_COMMUNITIES = gql`
  query GetPlanTagsByCommunities($clientName: String!, $communityIds: [Int!]!) {
    planTagsByCommunities(
      clientName: $clientName
      communityIds: $communityIds
    ) {
      tagId
      categoryId
      count
      categoryName
      tagName
    }
  }
`

export const GET_PROSPECT_BY_ID = gql`
  query GetPropsectById($clientName: String!, $id: Int!) {
    prospectById(clientName: $clientName, id: $id) {
      id
      email
      name
      firstName
      lastName
      phone
      picture
    }
  }
`

export const GET_PROSPECT_BY_EMAIL = gql`
  query GetProspectByEmail($clientName: String!, $email: String!) {
    prospectByEmail(clientName: $clientName, email: $email) {
      id
      email
      name
      firstName
      lastName
      phone
      picture
    }
  }
`
export const GET_PROSPECT_WITH_INTERIORS_BY_EMAIL = gql`
  query GetProspectByEmail($clientName: String!, $email: String!) {
    prospectByEmail(clientName: $clientName, email: $email) {
      id
      favorites {
        id
        interiors(clientName: $clientName) {
          id
          thumbnailSrc
        }
      }
    }
  }
`

export const GET_PROSPECT_BASE_INFO = gql`
  query GetProspectBaseInfo($email: String!) {
    prospectBaseInfo(email: $email) {
      id
      email
      preferredContactMethods
      phone
    }
  }
`

export const GET_PROSPECT_WITH_FAVORITES_BY_EMAIL = gql`
  query GetProspectByEmail($clientName: String!, $email: String!) {
    prospectByEmail(clientName: $clientName, email: $email) {
      id
      email
      name
      firstName
      lastName
      phone
      preferredContactMethods
      receivedFavorites {
        sentByProspectName
        sentByProspectEmail
        sentByProspectId
        receivedByProspectName
        receivedByProspectEmail
        clientId
        clientAltName
      }
      favorites {
        id
        clientName
        communityId
        planId
        elevationId
        lastUpdate
        lotId
        schemeId
        colorSelections
        fpOptSelections
        interiorDesignSelections
        garagePosition
        isMirrored
        community(clientName: $clientName) {
          active
          id
          name
          address
          caption
          description
          logo
          thumb
          pricing
          primarySiteplan(clientName: $clientName, active: true) {
            id
            lotLegend(clientName: $clientName) {
              id
              code
              name
              hex
            }
          }
          agents(clientName: $clientName) {
            id
            firstName
            lastName
            email
            phone
          }
        }
        plan(clientName: $clientName) {
          id
          communityId
          name
          bed
          bath
          cost
          defaultElevationId
          size
          exteriorAssets(clientName: $clientName) {
            src
            listIndex
            videoSrc
          }
        }
        elevation(clientName: $clientName) {
          active
          id
          bed
          bath
          communityId
          cost
          planId
          planName
          caption
          thumb
          size
          garagePosition
          defaultGaragePosition
          materialPalettes(clientName: $clientName) {
            materialId
            communityId
            planId
            elevationId
            name
          }
        }
        lot(clientName: $clientName) {
          id
          name
          salesStatus
          premium
          address
          dataName
          size
          cityName
          stateCode
          geoJson
          availableSchemes(clientName: $clientName) {
            id
            name
            internalName
            cost
          }
          inventory(clientName: $clientName) {
            id
            lotId
            planId
            elevationId
            communityId
            mlsId
            sqft
            beds
            baths
            floors
            schemeId
            closingDate
            photoFolder
            price
            photos(clientName: $clientName) {
              id
              src
              listIndex
            }
            sgtData(clientName: $clientName) {
              id
              sgtVendorId
              sgtData
            }
            appointmentsEnabled
            reserveHomeUrl
          }
        }
        scheme(clientName: $clientName) {
          id
          name
        }
        inventory(clientName: $clientName) {
          id
          lotId
          planId
          elevationId
          communityId
          mlsId
          sqft
          beds
          baths
          floors
          schemeId
          closingDate
          photoFolder
          price
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
          sgtData(clientName: $clientName) {
            id
            sgtVendorId
            sgtData
          }
          appointmentsEnabled
          reserveHomeUrl
        }
      }
      onlineReservations {
        id
        prospectReservationId
        favoriteId
        reservationStatus
        signatureDate
        secondaryBuyerReservations {
          signatureDate
        }
      }
    }
  }
`

export const GET_PROSPECT_SGT_APPOINTMENTS = gql`
  query GetProspectSgtAppointments(
    $clientName: String!
    $prospectId: Int!
    $inventoryId: Int!
  ) {
    prospectSgtAppointments(
      clientName: $clientName
      prospectId: $prospectId
      inventoryId: $inventoryId
    ) {
      preferredDate
    }
  }
`
export const GET_PROSPECT_RESERVATIONS = gql`
  query getProspectReservations($clientName: String!) {
    getProspectReservations(clientName: $clientName) {
      id
      prospectId
      favoriteId
      reservationStatus
      signatureDate
      secondaryBuyerReservations {
        signatureDate
      }
    }
  }
`

export const COMMUNITIES_PLANS = gql`
  query GetCommunitiesPlans(
    $clientName: String!
    $active: Boolean
    $bonafide: Boolean
  ) {
    communities(clientName: $clientName, active: $active, bonafide: $bonafide) {
      ...CommunityFields
      lots(clientName: $clientName, quickMoveInOnly: true) {
        ...LotFields
        inventory(clientName: $clientName) {
          ...InventoryFields
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
        }
      }
      plans(clientName: $clientName) {
        id
        name
        elevations(clientName: $clientName) {
          id
          caption
          thumb
          planName
          planDisplayName
        }
      }
    }
  }
  ${fragments.community}
  ${fragments.lot}
  ${fragments.inventory}
`

export const COMMUNITY = gql`
  query GetCommunityByName($clientName: String!, $communityName: String!) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      ...CommunityFields
      imgs(clientName: $clientName) {
        id
        src
        videoSrc
      }
      mainPhotos(clientName: $clientName) {
        id
        src
        listIndex
      }
      highlights(clientName: $clientName) {
        id
        name
        photos(clientName: $clientName) {
          id
          src
          listIndex
        }
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        src
        geoInfo(clientName: $clientName) {
          id
          siteplanId
          neLatitude
          neLongitude
          swLatitude
          swLongitude
          geoJson
        }
      }
      stdFeatureCategories(clientName: $clientName) {
        id
        name
        features(clientName: $clientName, communityName: $communityName) {
          id
          name
        }
      }
      plans(clientName: $clientName, active: true) {
        ...PlanFields
        elevations(clientName: $clientName, active: true) {
          ...ElevationFields
        }
      }
      lots(clientName: $clientName) {
        ...LotFields
        inventory(clientName: $clientName) {
          ...InventoryFields
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
        }
      }
    }
  }
  ${fragments.community}
  ${fragments.plan}
  ${fragments.elevation}
  ${fragments.lot}
  ${fragments.inventory}
`

export const COMMUNITY_IMGS_ONLY = gql`
  query GetCommunityByNameWithImgsOnly(
    $clientName: String!
    $communityName: String!
  ) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      id
      imgs(clientName: $clientName) {
        id
        src
      }
    }
  }
`

export const COMMUNITY_LITE = gql`
  query GetCommunityByNameLite($clientName: String!, $communityName: String!) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      ...CommunityFields
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        lotMetric
        src
        geoInfo(clientName: $clientName) {
          id
        }
        lotLegend(clientName: $clientName) {
          id
          code
          name
          hex
        }
      }
      stdFeatureCategories(clientName: $clientName) {
        id
        name
        features(clientName: $clientName, communityName: $communityName) {
          id
          name
        }
      }
    }
  }
  ${fragments.community}
`

export const COMMUNITY_LITE_2 = gql`
  query GetCommunityByNameLite2($clientName: String!, $communityName: String!) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      ...CommunityFields
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        lotMetric
        src
        geoInfo(clientName: $clientName) {
          id
        }
        lotLegend(clientName: $clientName) {
          id
          code
          name
          hex
        }
      }
      imgs(clientName: $clientName) {
        id
        src
      }
      stdFeatureCategories(clientName: $clientName) {
        id
        name
        features(clientName: $clientName, communityName: $communityName) {
          id
          name
        }
      }
    }
  }
  ${fragments.community}
`

export const COMMUNITY_NAME = gql`
  query GetCommunityName($clientName: String!, $communityId: Int!) {
    community(clientName: $clientName, communityId: $communityId) {
      id
      name
    }
  }
`

export const AGENTS_BY_CLIENT = gql`
  query GetAgentsByClint($clientName: String!) {
    agentsByClient(clientName: $clientName) {
      id
      firstName
      lastName
      picture
      email
      phone
    }
  }
`

export const COMMUNITY_PLANS = gql`
  query GetCommunityByNameWithPlans(
    $clientName: String!
    $communityName: String!
  ) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      id
      name
      pricing
      agents(clientName: $clientName) {
        id
        email
        phone
        firstName
        lastName
      }
      plans(clientName: $clientName) {
        ...PlanFields
        elevations(clientName: $clientName, active: true) {
          thumb
          ...ElevationFields
        }
      }
      schemes(clientName: $clientName) {
        id
        name
      }
    }
  }
  ${fragments.plan}
  ${fragments.elevation}
`

export const COMMUNITY_WITH_LOTS = gql`
  query GetCommunityByNameWithLots(
    $clientName: String!
    $communityName: String!
  ) {
    communityByName(clientName: $clientName, communityName: $communityName) {
      ...CommunityFields
      siteplans(clientName: $clientName, active: true) {
        id
        communityId
      }
      primarySiteplan(clientName: $clientName, active: true) {
        id
        src
        geoInfo(clientName: $clientName) {
          id
          siteplanId
          neLatitude
          neLongitude
          swLatitude
          swLongitude
          geoJson
        }
      }
      lots(clientName: $clientName, quickMoveInOnly: true) {
        ...LotFields
        inventory(clientName: $clientName) {
          ...InventoryFields
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
        }
      }
    }
  }
  ${fragments.community}
  ${fragments.lot}
  ${fragments.inventory}
`

export const COMMUNITY_FOR_REGISTRATION = gql`
  query COMMUNITY_FOR_REGISTRATION(
    $communityId: Int!
    $clientName: String!
    $planId: Int
  ) {
    community(
      communityId: $communityId
      clientName: $clientName
      planId: $planId
    ) {
      id
      name
      thumb
      bonafide
      mainPhotos(clientName: $clientName) {
        id
        src
      }
      plans(clientName: $clientName) {
        id
        communityId
        name
      }
    }
  }
`

export const ELEVATION_COMMUNITIES = gql`
  query GetElevationCommunities(
    $clientName: String!
    $elevationId: Int!
    $planId: Int!
  ) {
    elevation(
      clientName: $clientName
      elevationId: $elevationId
      planId: $planId
    ) {
      id
      planId
      communityId
      communities(clientName: $clientName, active: true) {
        id
        name
      }
    }
  }
`

export const ELEVATION_COLORS = gql`
  query GetElevationLayers(
    $clientName: String!
    $elevationId: Int!
    $planId: Int!
    $communityId: Int
  ) {
    elevation(
      clientName: $clientName
      elevationId: $elevationId
      planId: $planId
      communityId: $communityId
    ) {
      id
      planId
      caption
      communityId
      base
      thumb
      layers(clientName: $clientName) {
        materialId
        name
        materialCategory
        src
      }
      materialPalettes(
        clientName: $clientName
        planId: $planId
        communityId: $communityId
      ) {
        communityId
        planId
        elevationId
        materialId
        materialCategory
        name
        palettes {
          id
          name
          elevationId
          materialId
          selections(clientName: $clientName) {
            id
            name
            hex
            swatch
            type
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
          }
        }
      }
      schemes(
        clientName: $clientName
        planId: $planId
        communityId: $communityId
      ) {
        id
        name
        cost
        materials(clientName: $clientName) {
          id
          materialId
          materialCategory
          material
          name
          hex
          type
          swatch
          masonryLib
          colorId
          brickId
          stoneId
          customOverlayId
          paletteIds(clientName: $clientName, communityId: $communityId)
        }
      }
    }
  }
`

export const ELEVATION_STORIES_ONLY = gql`
  query GetElevationWithStoriesOnly(
    $clientName: String!
    $communityId: Int
    $planId: Int
    $elevationId: Int!
  ) {
    elevation(
      clientName: $clientName
      communityId: $communityId
      planId: $planId
      elevationId: $elevationId
    ) {
      id
      communityId
      planId
      stories(clientName: $clientName) {
        ${commonFields.story}
      }
    }
  }
`

export const FLOORPLANS_BY_ELEVATION = gql`
  query GetFloorplansByElevationId(
    $clientName: String!
    $planId: Int!
    $elevationId: Int!
    $communityId: Int!
  ) {
    elevation(
      clientName: $clientName
      planId: $planId
      elevationId: $elevationId
      communityId: $communityId
    ) {
      ...ElevationFields
      collection(clientName: $clientName) {
        id
        communityId
        name
        siteplan(clientName: $clientName, active: true) {
          id
          name
        }
      }
      communities(clientName: $clientName, excludeDefaultCommunity: true, active: true) {
        ...CommunityFields
      }
      floorplans(clientName: $clientName, planId: $planId, communityId: $communityId) {
        id
        groupId
        elevationId
        name
        standard
        src
        src2
        labelSvg
        fnum
        base
        optional
        cost
        size
        bed
        bath
        notes
        listIndex
        renderIndex
        lastUpdateTime
      }
      fpGroups(clientName: $clientName, planId: $planId, communityId: $communityId) {
        id
        groupType
        name
        autoselectLinkedChildParents
        primary(clientName: $clientName) {
          id
          groupId
          elevationId  
          standard
        }
        members(clientName: $clientName) {
          id
          groupId
          elevationId
          standard
          alternate(clientName: $clientName) {
            id
            name
            src
            src2
            labelSvg
            cost
            size
            bed
            bath
          }
        }
      }
      stories(clientName: $clientName, planId: $planId, communityId: $communityId) {
        ${commonFields.story}
      }
    }
  }
  ${fragments.community}
  ${fragments.elevation}
`

const optionFields = `
    id
    name
    cost
    hex
    description
    interiorListElementId
    overlaySrc
    overlaySwatchSrc
    listIndex
`

const listElementsFields = `
  id
  defaultElementListOptionId
  parentInteriorListElementId
  cost
  name
  description
  interiorId
  elementType
  listIndex
  overlaySwatchSrc
  interiorElement {
    id
    optionBlendMode
  }
  options {
    ${optionFields}
  }
`
export const HOME_SELECTION = gql`
  query GetHomeSelection(
    $clientName: String!
    $prospectIdentifier: String
    $homeIdentifier: String
    $favoriteIdentifier: Int
    $communityName: String!
    $planName: String
    $prospectId: Int
    $elevationId: Int
  ) {
    getHomeSelection(
      clientName: $clientName
      prospectIdentifier: $prospectIdentifier
      homeIdentifier: $homeIdentifier
      favoriteIdentifier: $favoriteIdentifier
      communityName: $communityName
      planName: $planName
      prospectId: $prospectId
      elevationId: $elevationId
    ) {
      json
      mortgage {
        downPayment
        downPercent
        interest
        term
      }
      interiors {
        id
        name
        baseImageSrc
        disclaimer
        interiorAssignments {
          id
          interiorId
          communityId
          planId
          elevationId
        }
        elementTrees {
          ${listElementsFields}
          children {
            ${listElementsFields}
            children {
              ${listElementsFields}
              children {
                ${listElementsFields}
              }
            }
          }
        }
      }
      prospect {
        id
        email
        name
        firstName
        lastName
        phone
        picture
      }
      mirrorElevation {
        id
        planId
        caption
        communityId
        base
        thumb
        layers(clientName: $clientName) {
          materialId
          name
          materialCategory
          src
        }
        materialPalettes(clientName: $clientName) {
          communityId
          planId
          elevationId
          materialId
          materialCategory
          name
          palettes {
            id
            name
            elevationId
            materialId
            selections(clientName: $clientName) {
              id
              name
              hex
              swatch
              type
              masonryLib
              colorId
              brickId
              stoneId
              customOverlayId
            }
          }
        }
        schemes(clientName: $clientName) {
          id
          name
          cost
          materials(clientName: $clientName) {
            id
            materialId
            materialCategory
            material
            name
            hex
            type
            swatch
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
            paletteIds(clientName: $clientName)
          }
        }
      }
      elevationColors {
        id
        active
        planId
        caption
        communityId
        base
        thumb
        cost
        bed
        bath
        size
        mirrorRolePrimary
        cost
        bed
        bath
        size
        layers(clientName: $clientName) {
          materialId
          name
          materialCategory
          src
        }
        materialPalettes(clientName: $clientName) {
          communityId
          planId
          elevationId
          materialId
          materialCategory
          name
          palettes {
            id
            name
            elevationId
            materialId
            selections(clientName: $clientName) {
              id
              name
              hex
              swatch
              type
              masonryLib
              colorId
              brickId
              stoneId
              customOverlayId
            }
          }
        }
        schemes(clientName: $clientName) {
          id
          name
          cost
          materials(clientName: $clientName) {
            id
            materialId
            materialCategory
            material
            name
            hex
            type
            swatch
            masonryLib
            colorId
            brickId
            stoneId
            customOverlayId
            paletteIds(clientName: $clientName)
          }
        }
      }
      siteplan {
        id
        name
        lotFontSize
        lotMetric
        lotWidth
        lotHeight
        master
        src
        unreleasedLotsHandle
        showUnreleasedLotNumber
        ...SiteplanSVGFields
        lotLegend(clientName: $clientName) {
          id
          code
          name
          hex
        }
        ...HotspotsFields
      }
      client {
        id
        name
        altName
        directoryName
        disclaimer
        trackingId
        logo
        website
      }
      plan {
        ...PlanFields
        exteriorAssets(clientName: $clientName) {
          src
          listIndex
          videoSrc
        }
        interiorPhotos(clientName: $clientName) {
          src
          listIndex
        }
      }
      floorplans
      {
        planId
        communityId
        elevationId
        defaultFloor
        stories {
          id
          communityId
          planId
          elevationId
          name
          fnum
          optional
          floorplans(clientName: $clientName) {
            ${commonFields.floorplan}
          }
          fpGroups(clientName: $clientName) {
            id
            name
            groupType
            primary(clientName: $clientName) {
              id
              groupId
              standard
              elevationId
              name
            }
            members(clientName: $clientName) {
              id
              name
              groupId
              standard
              elevationId
              fnum
              alternate(clientName: $clientName) {
                id
                src
                src2
                labelSvg
                cost
                size
                bed
                bath
              }
            }
          }
        }
      }
      community {
        ...CommunityFields
        imgs(clientName: $clientName) {
          id
          src
        }
        siteplans(clientName: $clientName, active: true) {
          id
          communityId
          src
        }
        primarySiteplan(clientName: $clientName, active: true) {
          id
          lotMetric
          src
          geoInfo(clientName: $clientName) {
            id
          }
          lotLegend(clientName: $clientName) {
            id
            code
            name
            hex
          }
        }
        stdFeatureCategories(clientName: $clientName) {
          id
          name
          features(clientName: $clientName, communityName: $communityName) {
            id
            name
          }
        }
      }
    }
  }
  ${fragments.community}
  ${fragments.plan}
  ${fragments.siteplanSVG}
  ${fragments.hotspots}
`

export const COMMUNITY_INTERIORS = gql`
  query activeInteriors($clientName: String!, $interior: InteriorInput!) {
    activeInteriors(clientName: $clientName, interior: $interior) {
      id
      name
      baseImageSrc
      thumbnailSrc
      disclaimer
    }
  }
`

export const GET_INTERIOR = gql`
  query activeInteriors($clientName: String!, $interior: InteriorInput!) {
    activeInteriors(clientName: $clientName, interior: $interior) {
      id
      name
      baseImageSrc
      thumbnailSrc
      disclaimer
      elements {
        id
        name
        clientName
        maskSrc
        listIndex
        optionType
        interiorId
        renderIndex
        options {
          name
          cost
          elementId
          id
          subtext
          overlaySrc
          overlaySwatchSrc
          color
          defaultOption
          listIndex
          nestedElementOptions {
            id
            elementId
            name
            subtext
            overlaySrc
            overlaySwatchSrc
            cost
            listIndex
            color
            defaultOption
          }
        }
      }
    }
  }
`

export const GET_INTERIOR_PACKAGES = gql`
  query interiorPackages($clientName: String!, $interiorId: Int) {
    interiorPackages(clientName: $clientName, interiorId: $interiorId) {
      id
      name
      description
      cost
      packageType
      interiorPackageMembers {
        packageId
        interiorId
        baseImageSrc
        name
        thumbnailSrc
        disclaimer
        interiorPackageMemberElements {
          interiorId
          packageId
          elementId
          optionId
          elementName
          elementOptionName
          hex
          overlaySwatchSrc
          def
          cost
          elementOptions {
            interiorId
            packageId
            elementId
            optionId
            elementName
            elementOptionName
            hex
            overlaySwatchSrc
            def
            cost
            listIndex
          }
        }
      }
    }
  }
`

export const GET_INTERIOR_PACKAGE = gql`
  query interiorPackage($clientName: String!, $currentInteriorPackageId: Int) {
    interiorPackages(
      clientName: $clientName
      currentInteriorPackageId: $currentInteriorPackageId
    ) {
      id
      name
      description
      cost
      packageType
      interiorPackageMembers {
        packageId
        interiorId
        baseImageSrc
        name
        thumbnailSrc
        disclaimer
        interiorPackageMemberElements {
          interiorId
          packageId
          elementId
          optionId
          elementName
          elementOptionName
          hex
          overlaySwatchSrc
          def
          cost
          elementOptions {
            interiorId
            packageId
            elementId
            optionId
            elementName
            elementOptionName
            hex
            overlaySwatchSrc
            def
            cost
          }
        }
      }
    }
  }
`

export const INVENTORY_CONSTRUCTION_STATUSES = gql`
  query getInventoryConstructionStatuses {
    __type(name: "InventoryConstructionStatus") {
      name
      enumValues {
        name
      }
    }
  }
`

export const LOT = gql`
  query GetLotWithInventory($clientName: String!, $lotId: Int!) {
    lot(clientName: $clientName, lotId: $lotId) {
      ...LotFields
      siteplanName(clientName: $clientName)
      siteplanDisplayName(clientName: $clientName)
      siteplanInfo {
        lotId
        siteplanId
      }
      siteplanName(clientName: $clientName)
      inventory(clientName: $clientName) {
        ...InventoryFields
        virTourUrl
        photos(clientName: $clientName) {
          id
          src
          listIndex
        }
        mlsPhotos
        includedFloorplans(clientName: $clientName) {
          id
          groupId
          elevationId
          standard
          name
        }
        plan(clientName: $clientName) {
          ...PlanFields
        }
        elevation(clientName: $clientName) {
          ...ElevationFields
        }
      }
    }
  }
  ${fragments.plan}
  ${fragments.elevation}
  ${fragments.lot}
  ${fragments.inventory}
`

export const LOT_INVENTORY_EXTERIOR_ONLY = gql`
  query LotWithInventoryExteriorConfigurationOnly(
    $clientName: String!
    $lotId: Int!
  ) {
    lot(clientName: $clientName, lotId: $lotId) {
      id
      geoJson
      inventory(clientName: $clientName) {
        id
        exteriorConfiguration(clientName: $clientName) {
          inventoryId
          scheme {
            id
            name
            materials(clientName: $clientName) {
              id
              materialId
              materialCategory
              material
              name
              hex
              type
              swatch
              masonryLib
              colorId
              brickId
              stoneId
              customOverlayId
            }
          }
          paletteElementSelections {
            inventoryId
            elementId
            paletteId
            paletteSelection {
              id
              name
              hex
              type
              swatch
              masonryLib
            }
          }
        }
      }
    }
  }
`

export const LOT_PLAN_ELEVATION_ONLY = gql`
  query GetLotWithPlanElevationOnly($clientName: String!, $lotId: Int!) {
    lot(clientName: $clientName, lotId: $lotId) {
      id
      geoJson
      inventory(clientName: $clientName) {
        id
        plan(clientName: $clientName) {
          ...PlanFields
        }
        elevation(clientName: $clientName) {
          ...ElevationFields
        }
      }
    }
  }
  ${fragments.plan}
  ${fragments.elevation}
`

export const LOT_SITEPLAN_INFO = gql`
  query GetLotWithSiteplanInfo($clientName: String!, $lotId: Int!) {
    lot(clientName: $clientName, lotId: $lotId) {
      ...LotFields
      siteplanName(clientName: $clientName)
      siteplanInfo {
        lotId
        siteplanId
        x
        y
      }
    }
  }
  ${fragments.lot}
`

export const MATERIALS = gql`
  query Materials($clientName: String!) {
    materials(clientName: $clientName) {
      id
      displayName
    }
  }
`

export const PLAN = gql`
  query GetPlanByName(
    $clientName: String!
    $communityName: String!
    $planName: String!
    $active: Boolean
  ) {
    planByName(
      clientName: $clientName
      communityName: $communityName
      planName: $planName
    ) {
      ...PlanFields
      exteriorAssets(clientName: $clientName) {
        src
        listIndex
        videoSrc
        caption
      }
      interiorPhotos(clientName: $clientName) {
        src
        listIndex
        caption
      }
      elevations(clientName: $clientName, active: $active) {
        ...ElevationFields
        collection(clientName: $clientName) {
          id
          communityId
          name
          siteplan(clientName: $clientName, active: true) {
            id
            name
          }
        }
        communities(
          clientName: $clientName
          excludeDefaultCommunity: true
          active: true
        ) {
          ...CommunityFields
        }
      }
    }
  }
  ${fragments.community}
  ${fragments.plan}
  ${fragments.elevation}
`

export const PLAN_BY_ID = gql`
  query GetPlanById($clientName: String!, $communityId: Int!, $planId: Int!) {
    plan(clientName: $clientName, communityId: $communityId, planId: $planId) {
      ...PlanFields
      exteriorAssets(clientName: $clientName) {
        src
        listIndex
        videoSrc
      }
      interiorPhotos(clientName: $clientName) {
        src
        listIndex
      }
      elevations(clientName: $clientName, active: true) {
        ...ElevationFields
        communities(
          clientName: $clientName
          excludeDefaultCommunity: true
          active: true
        ) {
          ...CommunityFields
        }
      }
    }
  }
  ${fragments.community}
  ${fragments.plan}
  ${fragments.elevation}
`

export const PLAN_NAME = gql`
  query GetPlanName($clientName: String!, $communityId: Int, $planId: Int!) {
    plan(clientName: $clientName, communityId: $communityId, planId: $planId) {
      id
      communityId
      displayName
      name
    }
  }
`

export const PLAN_NAME_ELEVS_COMMS = gql`
  query GetPlanName($clientName: String!, $communityId: Int, $planId: Int!) {
    plan(clientName: $clientName, communityId: $communityId, planId: $planId) {
      id
      communityId
      displayName
      name
      elevations(
        clientName: $clientName
        communityId: $communityId
        active: true
      ) {
        id
        planId
        communityId
        internalName
        mirrorElevationId
        mirrorRolePrimary
        caption
        thumb
        communities(
          clientName: $clientName
          excludeDefaultCommunity: true
          active: true
        ) {
          id
          name
        }
      }
    }
  }
`

export const SCHEME = gql`
  query GetScheme($clientName: String!, $communityId: Int, $schemeId: Int!) {
    scheme(
      clientName: $clientName
      communityId: $communityId
      schemeId: $schemeId
    ) {
      id
      name
      materials(clientName: $clientName) {
        id
        materialId
        materialCategory
        material
        name
        hex
        type
        swatch
        masonryLib
        colorId
        brickId
        stoneId
        customOverlayId
      }
    }
  }
`

export const SITEPLAN_BY_COMMUNITY_ID = gql`
  query GetSiteplanByCommunityId($clientName: String!, $communityId: Int!) {
    activeSiteplanByCommunityId(
      clientName: $clientName
      communityId: $communityId
      master: false
    ) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      active
      unreleasedLotsHandle
      showUnreleasedLotNumber
      ...SiteplanSVGFields
      geoInfo(clientName: $clientName) {
        id
        siteplanId
        neLatitude
        neLongitude
        swLatitude
        swLongitude
        geoJson
      }
      lotLegend(clientName: $clientName) {
        id
        code
        name
        hex
      }
      hotspots {
        id
        siteplanId
        name
        x
        y
        width
        height
        description
        thumb
        assets {
          id
          listIndex
          src
          videoUrl
          description
        }
      }
      lots(clientName: $clientName) {
        ...LotFields
        geoJson
        siteplanName(clientName: $clientName)
        siteplanDisplayName(clientName: $clientName)
        siteplanInfo {
          lotId
          siteplanId
          x
          y
        }
        excludedPlanElevations(clientName: $clientName) {
          planId
          elevationId
          planName
          planDisplayName
          elevationCaption
        }
        inventory(clientName: $clientName) {
          ...InventoryFields
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
          plan(clientName: $clientName) {
            ...PlanFields
          }
          elevation(clientName: $clientName) {
            ...ElevationFields
          }
        }
      }
      subSiteplans(clientName: $clientName, active: true) {
        id
        name
        displayName
        info(clientName: $clientName) {
          siteplanId
          thumb
          fontSize
          x
          y
          whiteLabel
          showsThumb
        }
        lots(clientName: $clientName) {
          id
          communityId
          salesStatus
          inventory(clientName: $clientName) {
            id
            communityId
            lotId
            planId
            elevationId
          }
          excludedPlanElevations(clientName: $clientName) {
            planId
            elevationId
            planName
            planDisplayName
            elevationCaption
          }
        }
      }
    }
  }
  ${fragments.plan}
  ${fragments.elevation}
  ${fragments.lot}
  ${fragments.inventory}
  ${fragments.siteplanSVG}
`

export const SITEPLAN_BY_ID = gql`
  query GetSiteplanById($clientName: String!, $id: Int!) {
    siteplanById(clientName: $clientName, id: $id) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      active
      unreleasedLotsHandle
      showUnreleasedLotNumber
      ...SiteplanSVGFields
      geoInfo(clientName: $clientName) {
        id
        siteplanId
        neLatitude
        neLongitude
        swLatitude
        swLongitude
        geoJson
      }
      lotLegend(clientName: $clientName) {
        id
        code
        name
        hex
      }
      hotspots {
        id
        siteplanId
        name
        x
        y
        width
        height
        description
        thumb
        assets {
          id
          listIndex
          src
          videoUrl
          description
        }
      }
      lots(clientName: $clientName) {
        ...LotFields
        geoJson
        siteplanName(clientName: $clientName)
        siteplanDisplayName(clientName: $clientName)
        siteplanInfo {
          lotId
          siteplanId
          x
          y
        }
        excludedPlanElevations(clientName: $clientName) {
          planId
          elevationId
        }
        inventory(clientName: $clientName) {
          ...InventoryFields
          photos(clientName: $clientName) {
            id
            src
            listIndex
          }
          plan(clientName: $clientName) {
            ...PlanFields
          }
          elevation(clientName: $clientName) {
            ...ElevationFields
          }
        }
      }
    }
  }
  ${fragments.plan}
  ${fragments.elevation}
  ${fragments.lot}
  ${fragments.inventory}
  ${fragments.siteplanSVG}
`

export const LOT_SITEPLAN_BY_ID = gql`
  query GetLotSiteplanById($clientName: String!, $id: Int!) {
    siteplanById(clientName: $clientName, id: $id) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      unreleasedLotsHandle
      ...SiteplanSVGFields
      lotLegend(clientName: $clientName) {
        id
        code
        name
        hex
      }
      ...HotspotsFields
    }
  }
  ${fragments.siteplanSVG}
  ${fragments.hotspots}
`

export const SITEPLAN_LITE_BY_ID = gql`
  query GetSiteplanLiteById($clientName: String!, $id: Int!) {
    siteplanById(clientName: $clientName, id: $id) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      active
      ...SiteplanSVGFields
      lotLegend(clientName: $clientName) {
        id
        code
        name
        hex
      }
      ...HotspotsFields
      lots(clientName: $clientName) {
        id
        communityId
        dataName
        name
        salesStatus
        premium
        externalId
        address
        size
        cityName
        stateCode
        zip
        postCode
        garagePosition
        siteplanName(clientName: $clientName)
        siteplanDisplayName(clientName: $clientName)
        siteplanInfo {
          lotId
          siteplanId
          x
          y
        }
      }
    }
  }
  ${fragments.hotspots}
  ${fragments.siteplanSVG}
`

export const SITEPLAN_LITE_BY_COMMUNITY_ID = gql`
  query GetSiteplanLiteByCommunityId($clientName: String!, $communityId: Int!) {
    activeSiteplanByCommunityId(
      clientName: $clientName
      communityId: $communityId
      master: false
    ) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      active
      ...SiteplanSVGFields
      lotLegend(clientName: $clientName) {
        id
        code
        name
        hex
      }
      ...HotspotsFields
      lots(clientName: $clientName) {
        id
        communityId
        dataName
        name
        salesStatus
        premium
        externalId
        address
        size
        cityName
        stateCode
        zip
        postCode
        garagePosition
        siteplanName(clientName: $clientName)
        siteplanDisplayName(clientName: $clientName)
        siteplanInfo {
          lotId
          siteplanId
          x
          y
        }
      }
      subSiteplans(clientName: $clientName, active: true) {
        id
        name
        displayName
        info(clientName: $clientName) {
          siteplanId
          thumb
          fontSize
          x
          y
          whiteLabel
          showsThumb
        }
        lots(clientName: $clientName) {
          id
          communityId
          salesStatus
          inventory(clientName: $clientName) {
            id
            communityId
            lotId
            planId
            elevationId
          }
          excludedPlanElevations(clientName: $clientName) {
            planId
            elevationId
          }
        }
      }
    }
  }
  ${fragments.hotspots}
  ${fragments.siteplanSVG}
`

export const SITEPLAN_LOTS_WITH_EXCLUDED_PLAN_ELEVATIONS = gql`
  query GetSiteplanLotsWithExcludedPlanElevations(
    $clientName: String!
    $id: Int!
  ) {
    siteplanById(clientName: $clientName, id: $id) {
      id
      name
      displayName
      lotFontSize
      lotMetric
      lotWidth
      lotHeight
      master
      src
      active
      ...SiteplanSVGFields
      subSiteplans(clientName: $clientName, active: true) {
        id
        name
        displayName
        lots(clientName: $clientName) {
          id
        }
      }
      lotLegend(clientName: $clientName) {
        id
        code
        name
        hex
      }
      ...HotspotsFields
      lots(clientName: $clientName) {
        id
        communityId
        dataName
        name
        salesStatus
        premium
        externalId
        address
        size
        cityName
        stateCode
        zip
        postCode
        siteplanName(clientName: $clientName)
        siteplanDisplayName(clientName: $clientName)
        siteplanInfo {
          lotId
          siteplanId
          x
          y
        }
        excludedPlanElevations(clientName: $clientName) {
          planId
          elevationId
          planName
          planDisplayName
          elevationCaption
        }
      }
    }
  }
  ${fragments.hotspots}
  ${fragments.siteplanSVG}
`

export const STORIES_BY_ELEVATION = gql`
  query GetFloorplansByElevationId(
    $clientName: String!
    $communityId: Int!
    $planId: Int!
    $elevationId: Int!
  ) {
    floorplansByElevationId(
      clientName: $clientName
      communityId: $communityId
      planId: $planId
      elevationId: $elevationId
    ) {
      planId
      communityId
      elevationId
      defaultFloor
      stories {
        id
        communityId
        planId
        elevationId
        name
        fnum
        optional
        floorplans(clientName: $clientName) {
          ${commonFields.floorplan}
        }
        fpGroups(clientName: $clientName) {
          id
          name
          groupType
          primary(clientName: $clientName) {
            id
            groupId
            elevationId  
            standard
            name
          }
          members(clientName: $clientName) {
            id
            name
            groupId
            elevationId  
            standard
            fnum
            alternate(clientName: $clientName) {
              id
              src
              src2
              labelSvg
              cost
              size
              bed
              bath
            }
          }
        }
      }
    }
  }
`

export const UPDATE_EMAIL_CAMPAIGN_LEAD_SUBSCRIPTION_STATUS = gql`
  mutation updateEmailCampaignLeadSubscriptionStatus(
    $clientName: String!
    $encryptedProspectId: String!
    $iv: String!
    $status: Boolean!
  ) {
    updateEmailCampaignLeadSubscriptionStatus(
      clientName: $clientName
      encryptedProspectId: $encryptedProspectId
      iv: $iv
      status: $status
    )
  }
`
